import React from "react";
import { useState, useEffect } from "react";
import axios from '../../api/axios.js';
import { FaSearch } from "react-icons/fa";
import { BsPencil, BsTrash } from "react-icons/bs";

const Usuarios = () => {

    const USR = 'http://localhost:8020/ConsejoEditorial/usuarios';

    const [users, setUsers] = useState([]);
    //const [search, setSearch] = useState('');

    useEffect(() => {
        getUsers();
    },[]);

    const getUsers  = async () => {
        const res = await axios.get('/usuarios');
        setUsers(res.data)
        //console.log(inv.nombre)
    }

    /*let dataSearch = [];
    if (!search) {
        dataSearch = users;
    } else {
        dataSearch = users.filter((item) => 
            item.nombre.toLowerCase().includes(search.toLocaleLowerCase())||
            item.linea_inv.toLowerCase().includes(search.toLocaleLowerCase())
        )
    }

    const handleChange = (e) =>{
        setSearch(e.target.value);
        //console.log(e.target.value);
    }*/

    return(
        <main id="main" className="main">
               <div className="row">
                <div className="col-md-12 mt-3">
                    <table className="table table-hover table-sm" id="table"> 
                    <thead className="table" id="table-header">
                    <tr>
                        <th>Nombre</th>
                        <th>Institución</th>
                        <th>Usuario</th>
                        <th>Acciones</th>
                    </tr>
                    </thead>
                    <tbody id="table-body">
                            { users.map( (result) => (
                                <tr key={result.id}>
                                    <td>{result.nombre}</td>
                                    <td>{result.inst_procedencia}</td> 
                                    <td>{result.email}</td>
                                    <td style={{width:'150px'}}>
                                        <button className="btn btn-sm btn-warning"><BsPencil /></button>&nbsp;
                                        <button className="btn btn-sm btn-danger"><BsTrash /></button>
                                    </td>
                                </tr>
                            )    
                        )}
                        </tbody>
                </table>
                </div>
            </div>
        </main>
    )
}

export default Usuarios;