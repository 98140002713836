import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "../../../api/axios";
import LoadingSpinner from "../../LoadingSpinner";
// import ModalISBN from "./modals/ModalISBN";
import { Link } from "react-router-dom";
import ModalEvaluacion from "./modals/ModalEvaluacion";

const ObrasEvaluadas = () => {
    const [obras, setObras] = useState([]);
    const [evaluaciones, setEvaluaciones] = useState([]);
    const [name, setName] = useState([]);
    const token = localStorage.getItem("token");
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [filaSeleccionada, setFilaSeleccionada] = useState(null);

    useEffect( ()=>{
        if(token) {
            axios.get(`/usuario`, {
                headers: {
                    token: token,
                },
            }).then(({data}) => setName(data.nombre)).
            catch((error) => console.log(error));
        }
        setTimeout(() => {
            getEvaluaciones();
            getObras();
            // getObras();
            setLoading(false);
        }, 2000)
        // console.log(obrasEvaluadas)
    },[token]);

    // const getObras = async () => {
    //     const res = await axios.get('/obras');
    //     setObras(res.data);
    // }

    const getObras = async () => {
        const res = await axios.get('/obras');
        setObras(res.data);
    }

    const getEvaluaciones = async () => {
        const res = await axios.get('/evaluaciones');
        setEvaluaciones(res.data);
    }

    const handleBotonClick = (id) => {
        const filaSeleccionada = evaluaciones.find((fila) => fila.id === id);
        setFilaSeleccionada(filaSeleccionada);
        setShow(true);
        //console.log(show);
    };

    const handleDownload = (uuid,filename) => {
        axios({
          url: `/file/correcciones/${uuid}/${filename}`,
          method: 'GET',
          responseType: 'blob'
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        });
    };

    const closeModal = () => {
        setShow(false);
        setFilaSeleccionada(null);
    }

    const effectLoading = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000)
    }

    const Columns = [
        {
            name: 'FOLIO',
            selector: row => row.uuid,
            sortable: true,
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'NOMBRE DEL AUTOR',
            selector: row => row.evaluado,
            sortable: true,
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        // {
        //     name: 'NOMBRE DEL EVALUADOR',
        //     selector: row => row.nombre_evaluador,
        //     sortable: true,
        //     style: {
        //         fontWeight: 'bold',
        //         background: '#d6dbdf',
        //         color: '#000',
        //         '&:hover': {
        //             background: '#ccc'
        //           },
        //     }
        // },
        {
            name: 'NOMBRE DE LA OBRA',
            selector: row => row.nombre_doc,
            sortable: true,
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'ESTATUS',
            selector: row => row.observaciones === 'Correcciones' ? row.observaciones + ' realizadas' : row.observaciones,
            sortable: true,
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'FECHA DE CREACIÓN',
            selector: row => row.createdAt,
            sortable: true,
            width: '165px',
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            name: 'ACCIONES',
            width: '200px',
            style: {
                background: '#d6dbdf',
                color: '#000',
            },
            cell: row =>
                row.observaciones === 'Correcciones' ?
                <>
                    <button onClick={()=>handleDownload(row.uuid,row.file_correcciones)} 
                    className="btn btn-sm btn-success">Descargar</button>
                    <Link style={{marginLeft: '10px'}} to={`/obras/obrasEvaluadas/${row.id}/${row.uuid}`} onClick={() => handleBotonClick(row.id)} className="btn btn-sm btn-primary">Observaciones</Link>
                </>
                :
                <>
                    <Link to={`/obras/obrasEvaluadas/${row.id}/${row.uuid}`} onClick={() => handleBotonClick(row.id)} className="btn btn-sm btn-primary">Observaciones</Link>
                </>
        },
    
    ];

    //modificar a evaluar por ISBN
    const obrasEvaluadas = evaluaciones.filter((obra) => {
        return obra.observaciones != 'Aceptada' && obra.observaciones != 'No publicable';
    });
    // const obrasEvaluadas = obras.filter((obra) => {
    //     return obra.estatus === 'Evaluada' && obra.estatus2 === 'Evaluada';
    // });

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    return(
        <main id="main" className="main">
              <div className="row">
                <div className="col-md-12 mt-3">                   
                    {
                    loading ? (
                            <LoadingSpinner/>
                    ) :
                    <DataTable
                        title="OBRAS EVALUADAS"
                        theme="solarized"
                        data={obrasEvaluadas}
                        columns={Columns}
                        pagination
                        responsive
                        paginationComponentOptions={paginationComponentOptions}
                    />
                    }
                </div>
                { show && (
                <ModalEvaluacion effectLoading={effectLoading} getEvaluaciones={getEvaluaciones} show={show} fila={filaSeleccionada} closeModal={closeModal} />
                )}
            </div>
        </main>
    )
}

export default ObrasEvaluadas;