import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "../../../api/axios";
import LoadingSpinner from "../../LoadingSpinner";
import ModalISBN from "./modals/ModalISBN";
import { Link } from "react-router-dom";

const ObrasAceptadas = () => {
    const [obras, setObras] = useState([]);
    const [name, setName] = useState([]);
    const token = localStorage.getItem("token");
    const [loading, setLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [isbn, setIsbn] = useState([]);

    useEffect( ()=>{
        if(token) {
            axios.get(`/usuario`, {
                headers: {
                    token: token,
                },
            }).then(({data}) => setName(data.nombre)).
            catch((error) => console.log(error));
        }
        setTimeout(() => {
            getObras();
            setLoading(false);
        }, 2000)
    },[token]);

    const getObras = async () => {
        const res = await axios.get('/obras');
        setObras(res.data);
    }

    const openModal = () => {
        setShow(true);
    }

    const closeModal = () => {
        setShow(false);
    }

    const Columns = [

        {
            name: 'NOMBRE DEL AUTOR',
            selector: row => row.nombre_autor,
            sortable: true,
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'NOMBRE DE LA OBRA',
            selector: row => row.nombre_obra,
            sortable: true,
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'LINEA DE INVESTIGACIÓN',
            selector: row => row.linea_inv,
            sortable: true,
            width: '250px',
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'ESTATUS',
            selector: row => row.estatus,
            sortable: true,
            width: '145px',
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'FECHA DE ACEPTACIÓN',
            selector: row => row.updatedAt,
            sortable: true,
            width: '145px',
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            name: 'ACCIONES',
            style: {
                background: '#d6dbdf',
                color: '#000',
            },
            cell: row =>
                <>
                { row.isbn !== null && row.isbn !== '' ? (
                   ''
                ) : (
                    <Link to={`/obras/obrasAceptadas/${row.id}`} onClick={openModal} className="btn btn-sm btn-primary">Agregar ISBN</Link>
                )
                }
                </>
        },
    
    ];

    //modificar a evaluar por ISBN
    const obrasEvaluadas = obras.filter((obra) => {
        return ( obra.estatus === 'Aceptada' ) && ( obra.estatus2 === 'Aceptada' )
    });

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    return(
        <main id="main" className="main">
              <div className="row">
                <div className="col-md-12 mt-3">                   
                    {
                    loading ? (
                            <LoadingSpinner/>
                    ) :
                    <DataTable
                        title="OBRAS ACEPTADAS"
                        theme="solarized"
                        data={obrasEvaluadas}
                        columns={Columns}
                        pagination
                        responsive
                        paginationComponentOptions={paginationComponentOptions}
                    />
                    }
                </div>
                <ModalISBN show={show} closeModal={closeModal} getObras={getObras}/>
            </div>
        </main>
    )
}

export default ObrasAceptadas;