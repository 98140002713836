import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "../../../../api/axios";
import { useParams } from "react-router-dom";

const ModalISBN = ({ show, closeModal, getObras}) => {
    const [input, setInput] = useState({
        isbn: "",
    });
    const { isbn } = input;
    const {id} = useParams();

    const updateISBN = async (e) => {
        e.preventDefault();
        const User = {
            isbn
        }
        await axios.put(`/asignar/${id}`, User);
        closeModal();
        getObras();
    }

    const onChange = (e) => {
        setInput({...input, [e.target.name]: e.target.value });
        //console.log(e.target.value)
    };

    return (
        <>
            <Modal show={show} onHide={closeModal}> 
                    <Modal.Header closeButton>
                    <Modal.Title>AGREGA ISNB</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={(e) => updateISBN(e)}>
                    <Modal.Body>
                        <div className="col-md-12 mb-2">
                            <h5>ISBN:</h5>
                            <input required placeholder="ISBN 0-0000-0000-0" type="text" 
                            name="isbn" onChange={(e)=>onChange(e)} className="form-control" autoComplete="off"/>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Cancelar</Button>
                    <Button type="submit" variant="primary">Aceptar</Button>
                    </Modal.Footer>
                    </Form>
            </Modal>
        </>
    )

}

export default ModalISBN;