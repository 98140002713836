import { useState, useEffect } from "react";
import axios from "../../../api/axios.js";
import { Link, useNavigate } from "react-router-dom";
import Logo from '../../../assets/image/logo-ciatej.jpg';
import { confirmAlert } from "react-confirm-alert";
import { Card, Form } from "react-bootstrap";
import { GoAlert } from 'react-icons/go';
import {AiOutlineDownload} from 'react-icons/ai';

const AltaObra = () => {
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);
    const [nombre_autor, setNombreAutor]= useState('');
    const [nombre_obra, setNombreObra] = useState('');
    const [linea_inv, setLineaInv] = useState('');
    const [obra, setObra] = useState('');
    const [carta_descrip, setCartaDescrip] = useState('');
    const [solicitud_pub, setSolicitudPub] = useState('');
    const [lineas, setLineas] = useState([]);
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [fileName, setFileName] = useState("");
    const [fileName2, setFileName2] = useState("");
    const [ id, setId ] = useState();
    const [name, setName] = useState([]);
    //datos para el envio de correos
    const [email, setEmail] = useState('');
    // const { email, text } = inputs;

    // const handleChange  = (e) => {
    //     setInputs({...inputs, [e.target.name]: e.target.value });
    // }

    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    useEffect(() => {
        getUsers();
        getLineasInv();
        if(token) {
            axios.get(`/usuario`, {
                headers: {
                    token: token,
                },
            }).then(({data}) => setId(data.id) & setName(data.nombre) & setEmail(data.email)).
            catch((error) => console.log(error));
        }
        // console.log(users)
    },[token]);

    const getUsers  = async () => {
        const res = await axios.get('/usuarios');
        setUsers(res.data)
    }

const createObra = async (e) => {
        e.preventDefault();
        try {
        const bodyFormData = new FormData();
        bodyFormData.set("email", email);
        bodyFormData.set("nombre_autor", name);
        bodyFormData.set("nombre_obra", nombre_obra);
        bodyFormData.set("linea_inv", linea_inv);
        bodyFormData.append("file1", file1);
        bodyFormData.append("file2", file2);
        const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
        }
        console.log('estes es el cuerpo del fomrulario '+bodyFormData)
        await axios.post('/crearObra', bodyFormData, config).then((resp) => {
            const { data } = resp;
            // console.log(id)
            setTimeout(() => {
                confirmAlert({
                    title: 'Felicitaciones!',
                    message:
                            `${data.message}`,
                    buttons: [
                        {
                            label: 'Continuar',
                            
                        },
                    ]
                })
            }, 500);
        })
        await axios.post('/sendMailCreateObraAdmin');
        navigate('/inicio');
        setNombreAutor('');
        setNombreObra('');
        setFile1(null);
        setFile2(null);
        setLineaInv('');
    }catch (error){
        console.error('Error al crear la obra:', error.message);
        setTimeout(() => {
            confirmAlert({
                title: <div title="Alerta"><center><GoAlert color="#f1c40f"/></center></div>,
                message:
                        "Solo se permiten archivos .pdf o .docx. Verifica tus archivos y vuelve a cargarlos.",
                buttons: [
                    {
                        label: 'Continuar',
                        
                    },
                ]
            })
        }, 500);
        navigate('/obras/alta');
    }
}

    const getLineasInv = async () => {
        const res = await axios.get('/lineas');
        setLineas(res.data)
        //console.log(res.data)
    }

    const fileSelected = (e) => {
        setFile1(e.target.files[0]);
        setFileName(e.target.files[0].name);
        //console.log(e.target.files[0].name);
    };

    const fileSelected2 = (e) => {
        setFile2(e.target.files[0]);
        setFileName2(e.target.files[0].name);
        //console.log(e.target.files[0].name);
    };

    const userEmailActive = users.filter(userSelected => userSelected.nombre === name).map(result => result.email);

    return(
        <main id="main" className="main">
            <div className="row">
            </div>
        <div className="row">
            <div className="col-md-12 mt-3">
              <form id="msform" method="POST" onSubmit={createObra} encType="multipart/form-data" >
                        <fieldset>
                        <h2 className="fs-title">Solicitud de obra</h2>
                        <hr/>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                <label className="form-label">Nombre de Autor</label>

                                <input disabled type="text" className="form-control" value={name} />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                <label className="form-label">Nombre / Titulo de la Obra</label>
                                <input hidden name="email" type="text" className="form-control" value={userEmailActive} readOnly/>
                                <input required type="text" className="form-control"value={nombre_obra} onChange={ (e)=> setNombreObra(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                            <div className="mb-3">
                                <label className="form-label">Linea de Investigación</label>
                                <Form.Select type="text" className="form-control" value={linea_inv}  
                                onChange={ (e)=> setLineaInv(e.target.value)} required >
                                    <option>Selecciona tu Linea de Investigación</option>
                                    {lineas.map(result => 
                                        <option key={result.id} value={result.nombre}>{result.nombre}</option>
                                    )};
                                </Form.Select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-3">
                                <label className="form-label">Solicitud de publicación <label style={{color: 'red'}}>*Descarga el documento para completarlo* </label></label>
                                    <div className="col-md-2">
                                        <Link to='/files/SolicitudPub.docx' target='_blank' className="btn btn-success " type="button">Descargar</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-3">
                                <label className="form-label">Obra</label>
                                <input type="file" className="form-control" name="file1" onChange={fileSelected} required/>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="mb-3">
                                <label className="form-label">Solicitud de Publicación</label>
                                <input type="file" className="form-control" name="file2" onChange={fileSelected2}/>
                                </div>
                            </div>
                        </div>                                 
                        <button type="submit" className='btn btn-primary mt-3'>ENVIAR SOLICITUD</button>
                        </fieldset>
                </form>
            </div>
        </div>
        </main>
    );
}

export default AltaObra;