import React from "react";
import { useState, useEffect } from "react";
import axios from "../../../api/axios.js";
import { BsTrash, BsPencil } from "react-icons/bs";
import { GoPlus } from 'react-icons/go';
import ModalAdd from "./modals/ModalAdd.js";
import { confirmAlert } from "react-confirm-alert";
//import Columns from "./modals/Columns.js";
import DataTable from 'react-data-table-component';
import { Button, Form, Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import {BsFillEyeFill, BsFillEyeSlashFill} from 'react-icons/bs';
import LoadingSpinner from "../../LoadingSpinner.js";

const CatalogoInv = () => {
    const [users, setUsers] = useState([]);
    // const dobleRol = 'Activo';
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    // const [isChecked, setIsChecked] = useState(false);
    const {id} = useParams();
    const [ ver, setVer ] = useState(false);
    const [inputs, setInputs] = useState({
        email: "",
        password: ""
    });
    const { email, password } = inputs;
    const [loading, setLoading] = useState(true);

    const updateUser = async (e) => {
        e.preventDefault();
        const User = {
            email,
            password,
        }
        await axios.put(`/updateUser/${id}`, User);
        handleClose();
        setLoading(true);
        setTimeout(() => {
            getUsers();
            setLoading(false);
        }, 2000)
        axios.post('/sendmailUpdateCredentials', { email, password })
    }

    const onChange = (e) => {
        setInputs({...inputs, [e.target.name]: e.target.value });
        //console.log(e.target.value)
    };

    const switchShown = () => setVer(!ver);

    const handleClose = () => {
        setOpen(false);
        setShowDelete(false);
    }
    const handleShow = () => {
        setOpen(true);
    }

    const openModal = () => {
        setShow(true);
    }

    const closeModal = () => {
        setShow(false);
    }

    useEffect(() => {
        setTimeout(() => {
            getUsers();
            setLoading(false);
        }, 2000)
    },[]);

    const getUsers  = async () => {
        const res = await axios.get('/usuarios');
        setUsers(res.data)
        //console.log(inv.nombre)
    }

    const deleteUser = async (id) => {
        await axios.delete(`/usuario/${id}`);
        getUsers();
    }

    const handleDelete = (id) => {
        confirmAlert({
          title: 'Eliminar',
          message: '¿Seguro que deseas eliminar este investigador?',
          buttons: [
            {
              label: 'Si',
              onClick: () => deleteUser(id)
            },
            {
              label: 'No',
            }
          ]
        });
    }
//Modo revisor funciona pero por seguridad no se habilita
    // const handleUpdate = (id) => {
    //     confirmAlert({
    //       title: 'Modo Autor',
    //       message: '¿Deseas hablilitar el modo autor a este revisor?',
    //       buttons: [
    //         {
    //           label: 'Si',
    //           onClick: () => updateMode(id),
    //         },
    //         {
    //           label: 'No',
    //         }
    //       ]
    //     })
    // }
    // const updateMode = async (id) => {
    //     await axios.put(`/usuario/${id}`,{
    //         dobleRol: dobleRol
    //     });
    //     getUsers();
    // }
    // const handleUpdateNo = (id) => {
    //     confirmAlert({
    //         title: 'Modo Autor',
    //         message: '¿Deseas deshablilitar el modo autor a este revisor?',
    //         buttons: [
    //           {
    //             label: 'Si',
    //             onClick: () => updateRole(id),
    //           },
    //           {
    //             label: 'No',
    //           }
    //         ]
    //       })
    // }
    // const updateRole = async (id) => {
    //     await axios.put(`/usuario/${id}`,{
    //         dobleRol: ''
    //     });
    //     getUsers();
    // }
    // const conditionalRowStyles = [
    //     {
    //       when: row => row.dobleRol === 'Activo',
    //       style: {
    //         backgroundColor: 'green',
    //         color: 'white',
    //         '&:hover': {
    //           cursor: 'pointer',
    //         },
    //       },
    //     },
    //     {
    //         when: row => row.calories < 400,
    //         style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'inerit' }),
    //       },
    // ]
 
    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    }

    const DataFilter = users.filter(item => item.rol === 2);

    const filteredUsers = DataFilter.filter(item => {
        return item.nombre.toLowerCase().includes(searchTerm.toLocaleLowerCase())||
        item.inst_procedencia.toLowerCase().includes(searchTerm.toLocaleLowerCase());
      })
   
    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const Columns = [

        {
            name: 'NOMBRE',
            selector: row => row.nombre,
            sortable: true,
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'EMAIL',
            selector: row => row.email,
            sortable: true,
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'LINEA DE INVESTIGACIÓN',
            selector: row => row.linea_inv,
            sortable: true,
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'INSITUCIÓN DE PROCEDENCIA',
            selector: row => row.inst_procedencia,
            sortable: true,
            width: '250px',
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'FECHA DE INGRESO',
            selector: row => row.createdAt,
            sortable: true,
            width: '160px',
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        // {
        //     name: 'MODO AUTOR',
        //     center: 'true',
        //     selector: row => row.dobleRol,
        //     cell: row => (
        //         <>
        //         {
        //             !row.dobleRol ?
        //             <div className="form-check form-switch">
        //             <input className="form-check-input" type="checkbox"
        //             style={{width: '40px', height: '23px'}} 
        //             id="flexSwitchCheckDisabled" disabled/>
        //             </div>
        //             :
        //             <div className="form-check form-switch">
        //             <input className="form-check-input" checked
        //             style={{width: '40px', height: '23px', backgroundColor: 'green', borderColor: 'green'}} 
        //             type="checkbox" id="flexSwitchCheckDefault" disabled/>
        //             </div>
        //         }
        //         </>
        //     ),
        //     width: '145px',
        //     style: {
        //         fontWeight: 'bold',
        //         background: '#d6dbdf',
        //         color: '#000',
        //         '&:hover': {
        //             background: '#ccc'
        //           },
        //     }
        // },
        {
            name: 'A C C I O N E S',
            width: '120px',
            cell: row => (
                <>
                {/* {
                row.dobleRol === 'Activo' ?
                <button onClick={()=>handleUpdateNo(row.id)}
                className="btn btn-primary" style={{marginRight: '10px'}}>
                    <FaUserPlus size={'20px'}/> </button>
                :
                <button onClick={()=>handleUpdate(row.id)}
                className="btn btn-sm btn-primary" style={{marginRight: '10px'}}>
                    <FaUserPlus size={'20px'}/> </button>
                } */}
                {/* Boton para editar esta en duda */}
                <Link to={`/catalogo/investigadores/${row.id}`} onClick={handleShow} 
                className="btn btn-sm btn-warning" style={{marginRight: '10px'}}><BsPencil size={'20px'}/></Link>
                
                <button onClick={()=>handleDelete(row.id)} 
                className="btn btn-sm btn-danger"><BsTrash size={'20px'}/></button>
                </>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            style: {
                background: '#d6dbdf',
                color: '#000',
            }
        },
    
    ];

    return(
        <main id="main" className="main">
              <div className="row">
                <div className="col-md-12 mt-3">                   
                    <button id="addInv" className="btn btn-success" onClick={openModal}>
                    <i className="bi-alarm"></i><GoPlus/> Agregar Investigador
                    </button>
                    <input id="inputSearch" className="form-control mb-3" type="text" 
                    placeholder="Buscar por nombre o institución..." value={searchTerm} 
                    onChange={handleSearch} />
                    {loading ? (
                        <LoadingSpinner/>
                    ) :
                    <DataTable
                        title="INVESTIGADORES"
                        columns={Columns}
                        data={filteredUsers}
                        theme="solarized"
                        pagination
                        noDataComponent="No hay datos para mostrar..."
                        paginationComponentOptions={paginationComponentOptions}
                        responsive
                    />
                    }
                    <Modal show={open} onHide={handleClose}>
                    <Form onSubmit={(e)=>updateUser(e)}>
                        <Modal.Header closeButton>
                        <Modal.Title>EDITA LOS DATOS SOLICITADOS</Modal.Title>
                        </Modal.Header>
                            <Modal.Body>
                                <div className="col-md-12 mb-2">
                                    <h5>Email:</h5>
                                    <input required placeholder="example@gmail.com" type="text" 
                                    name="email" onChange={(e)=>onChange(e)} className="form-control" autoComplete="off"/>
                                    </div>

                                    <div className="form-group row">
                                    <h5>Contraseña:</h5>
                                    <div className="col-md-10">
                                    <input required placeholder="********" name="password" className="form-control"
                                    type={ver ? 'text' : 'password'} autoComplete="off" onChange={(e)=>onChange(e)}/>
                                    </div>
                                    <div className="col-md-2">
                                    <Button variant="outline-dark" size="sm" onClick={switchShown}>
                                    {ver ? <BsFillEyeSlashFill size={'25px'}/> : <BsFillEyeFill size={'25px'}/>}
                                    </Button>
                                    </div>
                                    </div>
                            </Modal.Body>
                            <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Cancelar
                            </Button>
                            <Button variant="primary" type="submit">
                                Guardar Cambios
                            </Button>
                            </Modal.Footer>
                        </Form>
                </Modal>
                {/* <ModalEdit open={open} handleClose={handleClose} getUsers={getUsers} /> */}
                <ModalAdd show={show} closeModal={closeModal} getUsers={getUsers}/>
                </div>
            </div>
        </main>
    )
    

}


export default CatalogoInv;