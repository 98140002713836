import React from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "../../../api/axios.js";
import { useNavigate } from "react-router-dom";
import { FiAlertTriangle } from 'react-icons/fi';

const LogoutModal = ({open, handleClose}) => {
    const navigate = useNavigate();

    const logout = () => {
        localStorage.clear('token');
        handleClose();
        navigate('/');
    }

    return(
    <>
      <Modal show={open} onHide={handleClose} className="mt-5">
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className="centered-body"><FiAlertTriangle size={'60px'} color="#ffb41e"/><br/>¿Estas seguro que deseas cerrar sesión?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="danger" onClick={()=>logout()}>
            Cerrar Sesión
          </Button>
        </Modal.Footer>
      </Modal>
    </>
    );
}

export default LogoutModal;