import { Card } from "react-bootstrap";
import axios from '../api/axios.js';
import { useEffect, useState } from "react";
import {BsBook} from 'react-icons/bs';
import {VscError} from 'react-icons/vsc';
import { GoTasklist } from 'react-icons/go';
import {AiOutlineInfoCircle, AiOutlineFileDone, AiOutlineRight } from 'react-icons/ai';
import { FaRegEye } from "react-icons/fa";
import { Link } from "react-router-dom";
import ModalCorrecciones from "./views/obra/modals/ModalCorrecciones.js";

const Dashboard = () => {
    const [name, setName] = useState([]);
    const [id, setId] = useState([]);
    const [obras, setObras] = useState([]);
    const [role, setRole] = useState([]);
    const [users, setUsers] = useState([]);
    const [evaluations, setEvaluations] = useState([]);
    const [historicoVisible, setHistoricoVisible] = useState(false);
    const [show, setShow] = useState(false);

    const token = localStorage.getItem("token");

    const toggleHistorico = () => {
        setHistoricoVisible(!historicoVisible);
    };

    const openModal = () => { 
        setShow(true);
    }

    const closeModal = () => {
        setShow(false);
    }

    useEffect(() => {
        getEvaluaciones();
        getUsers();
        getObras();
        if (token) {
          axios.get('/usuario', {
              headers: {
                token: token,
              },
            })
            .then(({ data }) => setName(data.nombre) & setRole(data.rol) & setId(data.id))
            .catch((error) => console.error(error));
        }
        //console.log(orbasByInv)
      }, [token]);

    //Obtener archivo para la card de comentarios
    const descargarArchivo = (uuid,file) => {
        axios({
          url: `/file/comentarios/${uuid}/${file}`,
          method: 'GET',
          responseType: 'blob'
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file);
          document.body.appendChild(link);
          link.click();
        });
    };
    
    const getEvaluaciones = async () => {
        const res = await axios.get('/evaluaciones');
        setEvaluations(res.data);
    }

    const getObras = async () => {
        const res = await axios.get('/obras');
        setObras(res.data);
    }

    const getUsers = async () => {
        const res = await axios.get('/usuarios');
        setUsers(res.data);
    }
    //buscar obras en revision
    const obrasRev = obras.filter(obra => {
        return obra.nombre_autor === name && obra.estatus === 'En revisión'
    });
    //historico de obras admin
    const historicoObras = evaluations.filter(obra => ( obra.evaluado === name && 
        obra.observaciones === 'Comentarios' && obra.dictamen === 'Publicable condicionado'));
    //buscar uuid de obra
    const uuidUser = [...new Set(historicoObras.map((obra) => obra.uuid))];
    //buscar obras evaluadas
    const obrasEv = obras.filter(obra => ( obra.investigador_resp === name && obra.estatus === 'Evaluada')
        || ( obra.investigador_resp2 === name && obra.estatus2 === 'Evaluada'));
    //obras evaluadas user
    const obrasEvUser = obras.filter(obra => ( obra.nombre_autor === name && obra.estatus === 'Evaluada')
        || ( obra.nombre_autor === name && obra.estatus2 === 'Evaluada'));
    const obrasEva = evaluations.filter(obra => obra.nombre_evaluador === name);
    //buscar obras pendientes
    const obrasPen = obras.filter(obra => {
        return ( obra.investigador_resp === name && obra.estatus === 'En revisión' )
        || ( obra.investigador_resp2 === name && obra.estatus2 === 'En revisión' )
    });
    //buscar obras subidas
    const obrasSub = obras.filter(obra => {
        return obra.nombre_autor === name
    });
    //buscar obras publicadas
    const obrasPubByUser = obras.filter(obra => {
        return ( obra.nombre_autor === name ) && ( obra.estatus === 'Aceptada' ) && ( obra.estatus2 === 'Aceptada' )});
    const obrasPub = evaluations.filter(obra => {
        return ( obra.dictamen === 'Publicable en su versión actual' ) || ( obra.dictamen === 'Publicable condicionado' )});
    //buscar obras no publicables
    const obrasNoPub = evaluations.filter(obra => {
        return obra.dictamen === 'No publicable' && obra.evaluado === name
    });
    const obrasNoPubByUser = evaluations.filter(obra => {
        return ( obra.dictamen === 'No publicable')  && ( obra.evaluado === name )
    });
    //historico de obras por investigador
    const orbasByInv = evaluations.filter(obra => ( obra.nombre_evaluador === name ));
    //
    const obraEncontrada = evaluations.find(obra => obra.evaluado === name && obra.dictamen === 'No publicable');
//Cards de los usuarios
    const ObraByUserLog = () => {
        const persona = name;
        const usuarioExiste = obras.some(usuario => usuario.nombre_autor === persona);
        return (
            <>
            <div>
                <ModalCorrecciones getObras={getObras} show={show} closeModal={closeModal}/>
            </div>
                { usuarioExiste ?
                <div className="container">
                    <div className="row">
                    {/* Card obras subidas */}
                    { obrasSub.length > 0 ?
                        <div className="col-md-4"> 
                            <Card bg="light" className="mb-3">
                                    <Card.Header as="h3" className="card-header1"><BsBook size={'35px'}/> HISTORICO DE OBRAS ({obrasSub.length})</Card.Header>
                                    <div className="card-body">
                                        <div className="media d-flex">
                                        <div className="container" style={{ height: '320px', overflowY: 'auto' }}>
                                            {obras.filter(obra=> obra.nombre_autor === name).map(obraObtenida =>
                                            <ul key={obraObtenida.id}>
                                                <hr/>
                                                    <span><b>Nombre:</b> {obraObtenida.nombre_obra}</span>
                                                    <br/>
                                                    <span><b>Fecha de creación:</b> {obraObtenida.createdAt}</span>
                                                    <br/>
                                                    <span><b>Archivo:</b> {obraObtenida.filename}</span>
                                                    <br/>
                                                    <span><b>Folio:</b> {obraObtenida.uuid}</span>
                                            </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                    </div>
                    : ''
                    }
                    {/* Card obras en evaluadas */}
                    {/* { obrasEvUser.length > 0 ?
                    <div className="col-md-4"> 
                            <Card bg="light" className="mb-3">
                                <Card.Header as="h3" className="card-header2"><GoTasklist size={'35px'}/> OBRAS EVALUADAS ({obrasEvUser.length})</Card.Header>
                                <div className="card-body">
                                <div className="media d-flex">
                                <div className="container" style={{ height: '320px', overflowY: 'auto' }}>
                                    {obras.filter(obra=> obra.nombre_autor === name && obra.estatus === 'Evaluada' || obra.nombre_autor === name && obra.estatus2 === 'Evaluada').map(obraOptenida =>
                                    <ul key={obraOptenida.id}>
                                        <hr/>
                                            <span><b>Nombre:</b> {obraOptenida.nombre_obra}</span>
                                            <br/>
                                            <span><b>Linea de Investigación:</b> {obraOptenida.linea_inv}</span>
                                            <br/>
                                            <span><b>Fecha de movimiento:</b> {obraOptenida.updatedAt}</span>
                                        <hr/>
                                    </ul>
                                    )}
                                </div>
                            </div>
                            </div>
                        </Card>
                    </div>
                    : 
                    ''
                    } */}
                    {/* Card obras en revision */}
                    { obrasRev.length > 0 ?
                    <div className="col-md-4"> 
                            <Card bg="light" className="mb-3">
                                <Card.Header as="h3" className="card-header2"><GoTasklist size={'35px'}/> OBRAS EN REVISIÓN ({obrasRev.length})</Card.Header>
                                <div className="card-body">
                                <div className="media d-flex">
                                <div className="container" style={{ height: '320px', overflowY: 'auto' }}>
                                    {obras.filter(obra=> obra.nombre_autor === name && obra.estatus === 'En revisión').map(obraOptenida =>
                                    <ul key={obraOptenida.id}>
                                        <hr/>
                                            <span><b>Nombre:</b> {obraOptenida.nombre_obra}</span>
                                            <br/>
                                            <span><b>Linea de Investigación:</b> {obraOptenida.linea_inv}</span>
                                            <br/>
                                            <span><b>Fecha de movimiento:</b> {obraOptenida.updatedAt}</span>
                                        <hr/>
                                    </ul>
                                    )}
                                </div>
                            </div>
                            </div>
                        </Card>
                    </div>
                    : 
                    ''
                    }
                    {/* Card obras con comentarios */}
                    { historicoObras.length > 0 ?
                    <div className="col-md-4"> 
                            <Card bg="light" className="mb-3">
                                <Card.Header as="h3" className="card-header5"><FaRegEye size={'35px'}/> OBRAS CON OBSERVACIONES ({historicoObras.length})</Card.Header>
                                <div className="card-body">
                                <div className="media d-flex">
                                <div className="container" style={{ height: '320px', overflowY: 'auto' }}>
                                    <h5 style={{color: '#7e0404', textAlign: 'center', fontWeight: 'bold'}}>*Realiza los cambios pertientes en base a los comentarios realizados por el revisor y carga tu documento*</h5>
                                        <center><Link to={`/cargarDocumento/${uuidUser}`} onClick={openModal} className="btn btn-primary mt-1">Cargar documento</Link></center>
                                    <hr/>
                                    <span onClick={toggleHistorico}>
                                        {historicoVisible ? 'Ocultar comentarios' : 'Mostrar comentarios'}
                                        <AiOutlineRight onClick={toggleHistorico}/>
                                    </span>
                                    {
                                    historicoVisible && (
                                    <div>
                                    {historicoObras.map(obraObtenida => (
                                        <ul key={obraObtenida.id}>
                                        <hr />
                                        <span>
                                            <b>Nombre:</b> {obraObtenida.nombre_doc}
                                        </span>
                                        <br />
                                        <span>
                                            <b>Cambios obligatorios:</b> {obraObtenida.cambios_obligatorios}
                                        </span>
                                        <br />
                                        <span>
                                            <b>Fecha de movimiento:</b> {obraObtenida.updatedAt}
                                        </span>
                                        <br/>
                                        <span>
                                            <b>Obra con comentarios: </b>
                                        </span><button className="btn btn-sm btn-success" 
                                        onClick={()=>descargarArchivo(obraObtenida.uuid,obraObtenida.file)}>Descargar</button>
                                        <hr />
                                        </ul>
                                        ))}
                                    </div>
                                    )}
                                </div>
                            </div>
                            </div>
                        </Card>
                    </div>
                    : 
                    ''
                    }
                    {/* Card obras aceptadas */}
                    { obrasPubByUser.length > 0 ?
                    <div className="col-md-4"> 
                            <Card bg="light" className="mb-3">
                                <Card.Header as="h3" className="card-header3"><AiOutlineFileDone size={'35px'}/> OBRAS PUBLICADAS ({obrasPubByUser.length})</Card.Header>
                                <div className="card-body">
                                <div className="media d-flex">
                                {/* <div class="align-self-center">
                                    <AiFillFilePdf size={'60px'}/>
                                </div> */}
                                <div className="container" style={{ height: '320px', overflowY: 'auto' }}>
                                    {obrasPubByUser.map(obraOptenida =>
                                    <ul key={obraOptenida.id}>
                                        <hr/>
                                            <span><b>Nombre:</b> {obraOptenida.nombre_obra}</span>
                                            {/* <br/> */}
                                            {/* <span><b>Autor:</b> {obraOptenida.evaluado}</span> */}
                                            {/* <br/> */}
                                            {/* <span><b>ISBN:</b> {obras.filter(obra=> obra.nombre_autor === name && obra.estatus === 'Aceptada').map(obraSelect =>
                                                obraSelect.isbn
                                                )}</span> */}
                                            <br/>
                                            <span><b>Fecha de aceptación:</b> {obraOptenida.updatedAt}</span>
                                        <hr/>
                                    </ul>
                                    )}
                                </div>
                            </div>
                            </div>
                        </Card>
                    </div>
                    : ''
                    }
                    { obrasNoPub.length > 0 ?
                    <div className="col-md-4"> 
                            <Card bg="light" className="mb-3">
                                <Card.Header as="h3" className="card-header4"><VscError size={'35px'}/> OBRAS NO PUBLICABLES </Card.Header>
                                <div className="card-body">
                                <div className="media d-flex">
                                <div className="container" style={{ height: '320px', overflowY: 'auto' }}>
                                    {
                                    obraEncontrada && (
                                        <ul key={obraEncontrada.id}>
                                          <hr />
                                          <span><b>Nombre:</b> {obraEncontrada.nombre_doc}</span>
                                          <br />
                                          <span><b>Fecha de evaluación:</b> {obraEncontrada.createdAt}</span>
                                          <hr />
                                        </ul>
                                      )
                                    }
                                </div>
                            </div>
                            </div>
                        </Card>
                    </div>
                    : ''
                    }
                    </div>
                    </div>
                :
                <div className="col-md-12">
                <Card>
                <Card.Header as="h3" className="card-header"><AiOutlineInfoCircle size={'35px'}/> SIN OBRAS</Card.Header>
                    <Card.Body>
                        <div className="container mt-1">
                        <h4 style={{fontSize: '30px'}}>No cuentas con ninguna obra, sigue estos pasos para subir tu obra.</h4>
                                <ul>
                                <li style={{fontSize: '18px'}}><b>1.</b> En el menú del lado izquierdo, de clic en la sección de "Obras" y en el submenu "Generar".</li>
                                <li style={{fontSize: '18px'}}><b>2.</b> Descarga la solicitud de publicación, en el botón "Descargar".</li>
                                <li style={{fontSize: '18px'}}><b>3.</b> Una vez completa tu solicitud de publicación, procede a completar el formulario "Solicitud de obra", completa los campos solicitados y da clic en el botón "Enviar Solicitud de Obra".</li>
                            </ul>
                        </div>
                        
                    </Card.Body>
                </Card>
                </div>
                }
            </>
        )
    }
//Cards de los investigadores
    const ObraByUserLogInv = () => {
        const persona = name;
        const usuarioExiste = obras.some(usuario => usuario.investigador_resp === persona || usuario.investigador_resp2 === persona);
        // const obrasPend = obras.some(obra => obra.investigador_resp === name || obra.investigador_resp2 === name && 
        //     obra.estatus === 'En revisión' || obra.estatus2 === 'En revisión');
        return (
            <>
                { usuarioExiste ?
                    <div className="row">
                    { orbasByInv.length > 0 ?
                        <div className="col-md-4"> 
                            <Card bg="light" className="mb-3">
                                    <Card.Header as="h3" className="card-header5"><BsBook size={'35px'}/> HISTORICO DE OBRAS ({orbasByInv.length})</Card.Header>
                                    <div className="card-body">
                                        <div className="media d-flex">
                                        <div className="container" style={{ height: '320px', overflowY: 'auto' }}>
                                            {obras.filter(obra=> obra.investigador_resp === name || obra.investigador_resp2 === name).map(obraObtenida =>
                                            <ul key={obraObtenida.id}>
                                                <hr/>
                                                     <span><b>Folio:</b> {obraObtenida.uuid}</span>
                                                     <br/>
                                                    <span><b>Nombre:</b> {obraObtenida.nombre_obra}</span>
                                                    <br/>
                                                    <span><b>Fecha de creación:</b> {obraObtenida.createdAt}</span>
                                                <hr/>
                                            </ul>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                    </div>
                    : ''
                    }
                    {/* Card obras subidas */}
                    {obrasPen.length > 0 ?
                        <div className="col-md-4"> 
                            <Card bg="light" className="mb-3">
                                <Card.Header as="h3" className="card-header1"><BsBook size={'35px'}/> OBRAS PENDIENTES ({obrasPen.length})</Card.Header>
                                <div className="card-body">
                                <div className="media d-flex">
                                <div className="container" style={{ height: '320px', overflowY: 'auto' }}>
                                {obras.filter(obra => (obra.investigador_resp === name && obra.estatus === 'En revisión') || 
                                    (obra.investigador_resp2 === name && obra.estatus2 === 'En revisión') ).map(obraOptenida =>
                                    <ul key={obraOptenida.id}>
                                        <hr/>
                                        <Link to="/obras/solicitudes" style={{color: 'black'}}>
                                            <span><b>Nombre: </b>{obraOptenida.nombre_obra}</span>
                                            <br/>
                                            <span><b>Fecha de creación: </b>{obraOptenida.createdAt}</span>
                                        </Link>
                                        <hr/>
                                    </ul>
                                    )}
                                </div>
                            </div>
                            </div>
                        </Card>
                    </div>
                    : 
                    <div className="col-md-4"> 
                        <Card bg="light" className="mb-3">
                            <Card.Header as="h3" className="card-header1"><BsBook size={'35px'}/> OBRAS PENDIENTES</Card.Header>
                            <div className="card-body">
                            <div className="media d-flex">
                            <div className="container" style={{ height: '320px', overflowY: 'auto' }}>
                            <h5><b>Sin obras pendientes...</b></h5>
                            </div>
                        </div>
                        </div>
                        </Card>
                    </div>
                    }
                    {/* Card obras aceptadas */}
                    { obrasEv.length > 0 ?
                    <div className="col-md-4"> 
                            <Card bg="light" className="mb-3">
                                <Card.Header as="h3" className="card-header3"><AiOutlineFileDone size={'35px'}/> OBRAS EVALUADAS ({obrasEva.length})</Card.Header>
                                <div className="card-body">
                                <div className="media d-flex">
                                <div className="container" style={{ height: '320px', overflowY: 'auto' }}>
                                    {/* Este filter debe ser en base a evaluations, falta crear la ruta desde la API */}
                                    {evaluations.filter(obra => ( obra.nombre_evaluador === name)).map(obraObtenida =>
                                    <ul key={obraObtenida.id}>
                                        <hr/>
                                            <span><b>Nombre: </b>{obraObtenida.nombre_doc}</span>
                                            <br/>
                                            <span><b>Fecha de evaluación: </b>{obraObtenida.updatedAt}</span>
                                        <hr/>
                                    </ul>
                                    )}
                                </div>
                            </div>
                            </div>
                        </Card>
                    </div>
                    : ''
                    }
                    </div>
                :
                <div className="col-md-8">
                <Card bg="light">
                <Card.Header as="h3" className="card-header"><AiOutlineInfoCircle size={'35px'}/> SIN OBRAS</Card.Header>
                    <Card.Body>
                        <div className="container mt-1">
                            <h4>Aún no tienes obras asignadas, cuando tengas aparecerán aquí.</h4>
                        </div>
                        
                    </Card.Body>
                </Card>
                </div>
                }
            </>
        )
    }
//Cards de los admin
    const ObraByUserLogAdmin = () => {
        const persona = name;
        const usuarioExiste = users.some(usuario => usuario.nombre === persona);
        // const totalObras = obras.length;
        //const noPubObras = obras.length;
        //const obrasPub = obras.length;
        return (
            <>
                { usuarioExiste ?
                    <div className="row">
                    {/* Card obras subidas */}
                        <div className="col-md-4"> 
                            <Card bg="light" className="mb-3">
                                <Card.Header as="h3" className="card-header1"><BsBook size={'35px'}/> TOTAL DE OBRAS ({obras.length})</Card.Header>
                                <div className="card-body">
                                <div className="media d-flex">
                                <div className="container" style={{ height: '320px', overflowY: 'auto' }}>
                                    { obras.map(obrasObtenidas =>
                                    <ul key={obrasObtenidas.id}>
                                        <hr/>
                                            <span><b>Nombre:</b> {obrasObtenidas.nombre_obra}</span>
                                            <br/>
                                            <span><b>Autor:</b> {obrasObtenidas.nombre_autor}</span>
                                            <br/>
                                            <span><b>Correo:</b> {obrasObtenidas.email}</span>
                                            <br/>
                                            <span><b>Fecha de creación:</b> {obrasObtenidas.createdAt}</span>
                                        <hr/>
                                    </ul>
                                    )}
                                    {/* {obras.map(obraOptenida =>
                                    <ul key={obraOptenida.id}>
                                    <li><b>{obraOptenida.nombre_obra}</b></li>
                                    </ul>
                                    )} */}
                                </div>
                            </div>
                            </div>
                        </Card>
                    </div>
                    {/* Card obras en revision */}
                    { obrasNoPub.length > 0 ?
                    <div className="col-md-4"> 
                            <Card bg="light" className="mb-3">
                                <Card.Header as="h3" className="card-header4"><VscError size={'35px'}/> OBRAS NO PUBLICABLES ({obrasNoPub.length})</Card.Header>
                                <div className="card-body">
                                <div className="media d-flex">
                                <div className="container" style={{ height: '320px', overflowY: 'auto' }}>
                                    { obrasNoPub.map(obraOptenida =>
                                    <ul key={obraOptenida.id}>
                                        <hr/>
                                            <span><b>Nombre: </b>{obraOptenida.nombre_doc}</span>
                                            <br/>
                                            <span><b>Fecha de creación: </b>{obraOptenida.createdAt}</span>
                                        <hr/>
                                    </ul>
                                    )}
                                </div>
                            </div>
                            </div>
                        </Card>
                    </div>
                    : ''
                    }
                    {/* Card obras aceptadas */}
                    { obrasPub.length < 0  ?
                    <div className="col-md-4"> 
                            <Card bg="light" className="mb-3">
                                <Card.Header as="h3" className="card-header3"><AiOutlineFileDone size={'35px'}/> OBRAS PUBLICABLES ({obrasPub.length})</Card.Header>
                                <div className="card-body">
                                <div className="media d-flex">
                                <div className="container" style={{ height: '320px', overflowY: 'auto' }}>
                                    {/* Este filter debe ser en base a evaluations, falta crear la ruta desde la API */}
                                    

                                    { obrasPub.map(obraObtenida =>
                                    <ul key={obraObtenida.id}>
                                        <hr/>
                                            <span><b>Nombre: </b>{obraObtenida.nombre_doc}</span>
                                            <br/>
                                            <span><b>Dictamen: </b>{obraObtenida.dictamen}</span>
                                            <br/>
                                            <span><b>Fecha de creación: </b>{obraObtenida.createdAt}</span>
                                        <hr/>
                                    </ul>
                                    )}
                                    :
                                    <div>

                                    </div>
                                    
                                </div>
                            </div>
                            </div>
                        </Card>
                    </div>
                    : ''
                    }
                    </div>
                :
                <div className="col-md-8">
                </div>
                }
            </>
        )
    }

    return (
        <>
        <main id="main">
            <div className="row">
                {role === 1 ?
                    <ObraByUserLog/>
                : role === 2 ?
                <ObraByUserLogInv/>
                
                : <ObraByUserLogAdmin/> //Lo que se le mostrara al Consejo 
                }
            </div>
        </main>
        </>
    )
}

export default Dashboard;