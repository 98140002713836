import React, {useState, useEffect} from "react";
import { Modal, Form } from "react-bootstrap";
import axios from "../../../../api/axios";
import { useNavigate, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

const ModalRevision = ({show, closeModal, filaSeleccionada}) => {

    const estatus = 'Evaluada';
    const estatus2 = 'Evaluada';
    const [obras, setObras] = useState([]);
    const [file, setFile] = useState([]);
    const [filename, setFileName] = useState('');
    const [name, setName] = useState('');
    const {id,uuid} = useParams();
    const [nombre_doc, setNombre_Doc]= useState('');
    const [evaluado, setEvaluado]= useState('');
    const [redaccion_estilo, setRedaccion_Estilo]= useState('');
    const [estructura, setEsctructura]= useState('');
    const [tesis_principal, setTesis_Principal]= useState('');
    const [cambios_formales, setCambios_Formales]= useState('');
    const [cambios_formales_opc, setCambios_Formales_Opc]= useState('');
    const [C1, setC1]= useState('');
    const [C2, setC2]= useState('');
    const [C3, setC3]= useState('');
    const [C4, setC4]= useState('');
    const [C5, setC5]= useState('');
    const [C6, setC6]= useState('');
    const [C7, setC7]= useState('');
    const [calidad, setCalidad]= useState('');
    const [cambios_obligatorios, setCambios_Obligatorios]= useState('');
    const [cambios_opcionales, setCambios_Opcionales]= useState('');
    const [dictamen, setDictamen]= useState('');
    const [dictamen_razon, setDictamen_Razon]= useState('');
    const [comentarios, seteComentarios]= useState('');
    const token = localStorage.getItem("token");
    const navigate = useNavigate();

    useEffect( ()=>{
        if(token) {
            axios.get(`/usuario`, {
                headers: {
                    token: token,
                },
            }).then(({data}) => setName(data.nombre)).
            catch((error) => console.log(error));
        }
        getObras();
        // console.log(dictamen);
    },[token]);

    const inv1 = obras.filter((inv) => inv.investigador_resp === name && inv.uuid === uuid);
    const inv2 = obras.filter((inv) => inv.investigador_resp2 === name && inv.uuid === uuid);

    const findInv = async () => {
    switch (true) {
        case inv1.length > 0:
            axios.put(`/estatusEvaluacion/${uuid}`, {
                estatus: estatus
            });
            break;
        case inv2.length > 0:
            axios.put(`/estatusEvaluacion/${uuid}`, {
                estatus2: estatus2
            });
            break;
        default:
            // Manejar el caso en que ninguno de los filtros coincida
            break;
            }
        }

    const fileSelected = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
        // console.log(e.target.files[0].name);
    };

    const getObras = async () => {
        const res = await axios.get('/obras');
        setObras(res.data);
    }

    const createEvaluacion = async (e) => {
        e.preventDefault();
        // Valida si se va cargar un archivo en la seccion de comentarios
        if(file.length === 0) {
            await Promise.all([
                axios.post('/crearEvaluacion', {
                    uuid: uuid,
                    evaluado: usuarioEvaluado.toString(),
                    nombre_doc: docEvaluado.toString(),
                    nombre_evaluador: name,
                    redaccion_estilo: redaccion_estilo,
                    estructura: estructura,
                    tesis_principal: tesis_principal,
                    cambios_formales: cambios_formales,
                    cambios_formales_opc: cambios_formales_opc,
                    C1: C1,
                    C2: C2,
                    C3: C3,
                    C4: C4,
                    C5: C5,
                    C6: C6,
                    C7: C7,
                    calidad: calidad,
                    cambios_obligatorios: cambios_obligatorios,
                    cambios_opcionales: cambios_opcionales,
                    dictamen: dictamen,
                    dictamen_razon: dictamen_razon,
                    comentarios: comentarios,
                }),
                findInv()
                ]).then((resp) => {
                // const { data } = resp;
                setTimeout(() => {
                    confirmAlert({
                        title: 'Felicitaciones!',
                        message:
                                'Evaluación completada con exito!',
                        buttons: [
                            {
                                label: 'Continuar',
                                
                            },
                        ]
                    })
                }, 1000);
                getObras();
                closeModal();
                setTesis_Principal('');
                setCambios_Formales('');
                setCambios_Formales_Opc('');
                setCambios_Obligatorios('');
                setCambios_Opcionales('');
                setDictamen_Razon('');
                seteComentarios('');
                navigate('/inicio');
            }).catch(error => {
                console.log('error en la solicitud', error);
            });
        } else {
            const email2 = 'consejoeditorial@ciatej.mx';
            const bodyFormData = new FormData();
            bodyFormData.set("evaluado", usuarioEvaluado);
            bodyFormData.set("nombre_doc", docEvaluado);
            bodyFormData.set("nombre_evaluador", name);
            bodyFormData.set("redaccion_estilo", redaccion_estilo);
            bodyFormData.set("estructura", estructura);
            bodyFormData.set("tesis_principal", tesis_principal);
            bodyFormData.set("cambios_formales", cambios_formales);
            bodyFormData.set("cambios_formales_opc", cambios_formales_opc);
            bodyFormData.set("C1", C1);
            bodyFormData.set("C2", C2);
            bodyFormData.set("C3", C3);
            bodyFormData.set("C4", C4);
            bodyFormData.set("C5", C5);
            bodyFormData.set("C6", C6);
            bodyFormData.set("C7", C7);
            bodyFormData.set("calidad", calidad);
            bodyFormData.set("cambios_obligatorios", cambios_obligatorios);
            bodyFormData.set("cambios_opcionales", cambios_opcionales);
            bodyFormData.set("dictamen", dictamen);
            bodyFormData.set("dictamen_razon", dictamen_razon);
            bodyFormData.set("comentarios", comentarios);
            bodyFormData.append("file", file);
            const config = {
                headers: {
                  'content-type': 'multipart/form-data',
                },
            }
            await Promise.all([
                axios.post(`/evaluacion/${uuid}`, bodyFormData, config),
                findInv()
                ]).then((resp) => {
                // const { data } = resp;
                axios.post('/sendMailComments', {
                    email1: filaSeleccionada.email,
                    email2: email2,
                    uuid: uuid
                })
                setTimeout(() => {
                    confirmAlert({
                        title: 'Felicitaciones!',
                        message:
                                'Evaluación completada con exito!',
                        buttons: [
                            {
                                label: 'Continuar',
                                
                            },
                        ]
                    })
                }, 1000);
                getObras();
                closeModal();
                setTesis_Principal('');
                setCambios_Formales('');
                setCambios_Formales_Opc('');
                setCambios_Obligatorios('');
                setCambios_Opcionales('');
                setDictamen_Razon('');
                seteComentarios('');
                navigate('/inicio');
            }).catch(error => {
                console.log('error en la solicitud', error);
            });
                
        }
    }
    //Obtener el nombre de la obra evaluada
    const docEvaluado = obras.filter(obra=> obra.uuid === uuid).map(obraObtenida => obraObtenida.nombre_obra);
    const usuarioEvaluado = obras.filter(obra=> obra.uuid === uuid).map(obraObtenida => obraObtenida.nombre_autor);

    return (
        <Modal show={show} onHide={closeModal} size='lg'>
        <Modal.Header closeButton >
            <h3 className="modal-title"><b>Evaluar obra</b></h3>
        </Modal.Header>
        <Form method="POST" encType="multipart/form-data" onSubmit={createEvaluacion}>
            <div className="modal-content">
            <div className="col-md-12">
                <br></br>
                <div className="modal-body" style={{ height: '700px', overflowY: 'auto' }}>
                    <div className="col-md-8">
                        <h5>Documento evaluado:</h5>
                        <input type='text' hidden readOnly name="evaluado" value={usuarioEvaluado} className="form-control"/>
                        <input type='text' readOnly name="nombre_doc" value={docEvaluado} onChange={ (e)=> setNombre_Doc(e.target.value)} className="form-control"/>
                    </div>
                    <div className="col-md-8">

                        <input hidden type='text' value={name} name="nombre_evaluador" readOnly className="form-control"/>
                    </div>
                    <br></br>
                    <p><b>I. Evaluación de aspectos formales:</b></p>
                    <div className="col-md-4">
                        <p>1. Redacción y estilo:</p>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" value="Claro" name="redaccion_estilo" type="radio" onChange={ (e)=> setRedaccion_Estilo(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Claro
                            </label>
                        </div>
                        <div className="form-check form-check-inline">  
                            <input className="form-check-input" name="redaccion_estilo" type="radio" value="Cofuso" onChange={ (e)=> setRedaccion_Estilo(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Confuso
                            </label>
                        </div>
                    </div>
                    <div className="col-md-6">
                    <br></br>
                    <p>2. Estructura:</p>
                    <div className="form-check form-check-inline">
                    <input className="form-check-input" value="Adecuada" type="radio" onChange={ (e)=> setEsctructura(e.target.value)} name="estructura" id="flexCheckDefault"/>
                    <label className="form-check-label" htmlFor="flexCheckChecked">
                        Adecuada
                    </label>
                    </div>
                    <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="estructura" value="Inadecuada" onChange={ (e)=> setEsctructura(e.target.value)} id="flexCheckDefault"/>
                    <label className="form-check-label" htmlFor="flexCheckChecked">
                        Inadecuada
                    </label>
                    </div>
                    </div>
                    <br></br>
                    <div className="col-md-12">
                        <p>3. Tesis principal, importancia y contribución para el 
                            desarrollo del conocimiento social/científico/ tecnológico 
                            (impacto):</p>
                        <textarea name="tesis_principal" value={tesis_principal} onChange={ (e)=> setTesis_Principal(e.target.value)} className="form-control"/>
                    </div>
                    <div className="col-md-12">
                        <p>4. Cambios formales obligatorios para que el trabajo sea aceptado (en caso de tenerlos):</p>
                        <textarea name="cambios_formales" value={cambios_formales} onChange={ (e)=> setCambios_Formales(e.target.value)} className="form-control"/>
                    </div>
                    <div className="col-md-12">
                        <p>5. Cambios formales opcionales que ayudarían a mejorar el trabajo (en caso de tenerlos):</p>
                        <textarea name="cambios_formales_opc" value={cambios_formales_opc} onChange={ (e)=> setCambios_Formales_Opc(e.target.value)} className="form-control"/>
                    </div>
                    <br></br>
                    <p><b>II. Evaluación del contenido del trabajo:</b></p>
                    <div className="row">
                        <div className="col-md-7">
                            <h5>CRITERIOS</h5>
                                <p>1. Pertenencia del tema</p>
                        </div>
                        <div className="col-md-5">
                            <h5>CALIDAD</h5>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" value="Alta" type="radio" name="C1" onChange={ (e)=> setC1(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Alta
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" value="Mediana" type="radio" name="C1" onChange={ (e)=> setC1(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Mediana
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Baja" name="C1" onChange={ (e)=> setC1(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Baja
                            </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <p>2. Originalidad del planteamiento</p>
                        </div>
                        <div className="col-md-5">
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Alta" name="C2" onChange={ (e)=> setC2(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Alta
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Media" name="C2" onChange={ (e)=> setC2(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Mediana
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Baja" name="C2" onChange={ (e)=> setC2(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Baja
                            </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <p>3. Metodología utilizada</p>
                        </div>
                        <div className="col-md-5">
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Alta" name="C3" onChange={ (e)=> setC3(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Alta
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Media" name="C3" onChange={ (e)=> setC3(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Mediana
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Baja" name="C3" onChange={ (e)=> setC3(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Baja
                            </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <p>4. Desarrollo y conclusiones</p>
                        </div>
                        <div className="col-md-5">
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Alta" name="C4" onChange={ (e)=> setC4(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Alta
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Media" name="C4" onChange={ (e)=> setC4(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Mediana
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Baja" name="C4" onChange={ (e)=> setC4(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Baja
                            </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <p>5. Calidad de la redacción</p>
                        </div>
                        <div className="col-md-5">
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Alta" name="C5" onChange={ (e)=> setC5(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Alta
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Media" name="C5" onChange={ (e)=> setC5(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Mediana
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Baja" name="C5" onChange={ (e)=> setC5(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Baja
                            </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <p>6. Bibliografía adecuada y actualizada</p>
                        </div>
                        <div className="col-md-5">
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Alta" name="C6" onChange={ (e)=> setC6(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Alta
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Media" name="C6" onChange={ (e)=> setC6(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Mediana
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Baja" name="C6" onChange={ (e)=> setC6(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Baja
                            </label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <p>7. Presentación de cuadros y gráficos</p>
                        </div>
                        <div className="col-md-5">
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Alta" name="C7" onChange={ (e)=> setC7(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Alta
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Media" name="C7" onChange={ (e)=> setC7(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Mediana
                            </label>
                            </div>
                            <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" value="Baja" name="C7" onChange={ (e)=> setC7(e.target.value)} id="flexCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Baja
                            </label>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <b>En conjunto el trabajo es:</b>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-check form-check-inline">
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                De alta calidad
                            </label>
                            <input className="form-check-input" name="calidad" value="De alta calidad" type="radio" onChange={ (e)=> setCalidad(e.target.value)} id="flexCheckDefault"/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-check form-check-inline">
                            <label className="form-check-label"  htmlFor="flexCheckChecked">
                                De media calidad
                            </label>
                            <input className="form-check-input" name="calidad" type="radio" value="De media calidad" onChange={ (e)=> setCalidad(e.target.value)} id="flexCheckDefault"/>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                De baja calidad
                            </label>
                            <input className="form-check-input" name="calidad" value="De baja calidad" type="radio" onChange={ (e)=> setCalidad(e.target.value)} id="flexCheckDefault"/>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className="col-md-12">
                        <p>8. Cambios de contenido obligatorios para que el trabajo sea aceptado (en caso de tenerlos):</p>
                        <textarea className="form-control" value={cambios_obligatorios} onChange={ (e)=> setCambios_Obligatorios(e.target.value)}/>
                    </div>
                    <br></br>
                    <div className="col-md-12">
                        <p>9. Cambios de contenido opcionales que ayudarían a mejorar el trabajo (en caso de tenerlos):</p>
                        <textarea className="form-control" value={cambios_opcionales} onChange={ (e)=> setCambios_Opcionales(e.target.value)}/>
                    </div>
                    <br></br>
                    <p><b>III. Dictamen general:</b></p>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-check form-check-inline">
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                Publicable en su versión actual
                            </label>
                            <input className="form-check-input" name="dictamen" type="radio" value="Publicable en su versión actual" onChange={ (e)=> setDictamen(e.target.value)}  id="flexCheckDefault"/>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-check form-check-inline">
                            <label className="form-check-label"  htmlFor="flexCheckChecked">
                                Publicable condicionado
                            </label>
                            <input className="form-check-input" name="dictamen" type="radio" value="Publicable condicionado" onChange={ (e)=> setDictamen(e.target.value)} id="flexCheckDefault"/>
                            </div>
                        </div>
                        <div className="col-md-4">
                        <div className="form-check form-check-inline">
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                No publicable
                            </label>
                            <input className="form-check-input" name="dictamen" type="radio" value="No publicable" onChange={ (e)=> setDictamen(e.target.value)} id="flexCheckDefault"/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <b><p>IV. Dictamen razonado. Describir brevemente el principal mérito o demérito del trabajo:</p></b>
                        <textarea className="form-control" value={dictamen_razon} onChange={ (e)=> setDictamen_Razon(e.target.value)}/>
                    </div>
                    <div className="col-md-12">
                        <b><p>V. Comentarios adicionales (sólo si se consideran necesarios):</p></b>
                        <textarea className="form-control" value={comentarios} onChange={ (e)=> seteComentarios(e.target.value)}/>
                    </div>
                    <div className="col-md-12">
                        <b><p>VI. Si la obra tiene observaciones, favor de subir el documento correspondiente: </p></b>
                        <input onChange={fileSelected} name="filename" className="form-control" type="file"
                         />
                    </div>
                </div>
            </div>
            <div className="modal-footer mt-3">
                <button id="btn-cancel" onClick={closeModal} type="button" 
                className="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                <button type="submit" className="btn btn-primary">Generar evaluación</button>
            </div>
            </div>
            </Form>
    </Modal>
      )
}

export default ModalRevision;