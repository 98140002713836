import React from "react";
import "./App.css";
import Login from './components/login.js';
import Register from './components/register.js';
import MenuItem from './components/sidenavbar.js';
import AltaObra from './components/views/obra/AltaObra.js';
import Usuarios from './components/views/usuarios.js';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ObrasGeneradas from "./components/views/obra/ObrasGeneradas.js";
import AsignarObra from "./components/views/obra/AsignarObra.js";
import RegisterInv from "./components/registerInv.js";
import CatalogoInv from "./components/views/catalogos/investigadores.js";
import LineasInv from "./components/views/catalogos/lineasInv.js";
import Dashboard from "./components/Dashboard";
import ObrasEvaluadas from "./components/views/obra/ObrasEvaluadas.js";
import ObrasPublicadas from "./components/views/obra/ObrasPublicadas.js";
import ObrasAceptadas from "./components/views/obra/ObrasAceptadas.js";
import ProtectedRoutes from "./ProtectedRoutes.js";
import ExpireToken from "./ExpireToken.js";
function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <ExpireToken/>
          <MenuItem />
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/register' element={<Register />} />
                
                //Rutas protegidas
                <Route element={<ProtectedRoutes />}>
                <Route path="/inicio" element={<Dashboard/>}/>
                <Route path='/register/inv' element={<RegisterInv />} />
                <Route path='/obras/alta' element={<AltaObra />} />
                <Route path="/catalogo/investigadores" element={<CatalogoInv/>} />
                <Route path="/catalogo/investigadores/:id" element={<CatalogoInv/>} />
                <Route path="/catalogo/lineas_investigacion" element={<LineasInv/>} />
                <Route path='/usuarios' element={<Usuarios />} />
                <Route path='/obras/solicitudes' element={<ObrasGeneradas />} />
                <Route path='/evaluacion/:uuid' element={<ObrasGeneradas />} />
                <Route path='/cargarDocumento/:uuid' element={<Dashboard />} />
                <Route path='/obras/asignar' element={<AsignarObra />} />
                <Route path='/obras/asignar/:id' element={<AsignarObra />} />
                <Route path='/obras/obrasEvaluadas' element={<ObrasEvaluadas />} />
                <Route path='/obras/obrasEvaluadas/:id/:uuid' element={<ObrasEvaluadas />} />
                <Route path='/obras/obrasAceptadas' element={<ObrasAceptadas />} />
                <Route path='/obras/obrasAceptadas/:id' element={<ObrasAceptadas />} />
                <Route path='/obras/obrasPublicadas' element={<ObrasPublicadas />} />
                </Route>

            </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;