import Logo from '../assets/image/logo-ciatej.jpg'; 
import { Link } from 'react-router-dom';
import '../assets/css/register.css';
import { FaLock } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import '../assets/css/register.css';
import { FaUserAlt } from "react-icons/fa";
import { GoAlert } from "react-icons/go";
import axios from '../api/axios.js';
import { confirmAlert } from 'react-confirm-alert';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';


const Register = () => {
    const navigate = useNavigate();

    const [inputs, setInputs] = useState({
        rol: "1",
        nombre: "",
        email: "",
        password: ""
    });
    const [loading, setLoading] = useState(false);
    const [ message, setMessage ] = useState('');
    const { rol, nombre, email, password } = inputs;

    const onChange = (e) => {
        setInputs({...inputs, [e.target.name]: e.target.value });
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        if(nombre !== '' && email !== '' && password !== '') {
            const User = {
                rol,
                nombre,
                email,
                password,
            }
            setLoading(true);
            await axios.post('/registrar', User).then(({data}) => {
                setMessage(data.message)
                setInputs({
                    rol: "1",
                    nombre: "",
                    email: "",
                    password: ""
                });
                confirmAlert({
                    title: 'Felicitaciones',
                    message:
                            'Tu cuenta a sido creada con exito!',
                    buttons: [
                        {
                            label: 'Continuar',
                        }
                    ]
                })
                axios.post('/sendmailRegister', User)
                navigate('/')
            }).catch((error) => {
                setTimeout(() => {
                    setMessage('Ya existe un usuario con ese correo electrónico!');
                    setLoading(false);
                }, 1500);
            })
        }
    } 

        return(
                <>
                <div id="background-login">
                        <div className='container'>
                            <div className="col-lg-12">
                            {message && 
                                <div className="centered-container alert alert-warning mt-5" role="alert">
                                    <GoAlert size={'25px'}/> <b>{message}</b>
                                </div>
                            }
                                <form className="text-center form-register" onSubmit={(e)=> onSubmit(e)}>
                                <img src={Logo} className="logo-log mb-4" alt="logo" />
                                    <div className="mb-3">
                                        <label className="form-label input-name">Nombre Completo</label>
                                        <div className='input-group'>
                                            <span className="input-group-text"><FaUserAlt /></span>
                                            <input required className="form-control input-login" autoComplete='off'
                                            id='Nombre' onChange={(e)=> onChange(e)} name="nombre" />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label login-email">Correo Electrónico</label>
                                        <div className='input-group'>
                                            <span className="input-group-text"><GrMail /></span>
                                            <input required type="email" className="form-control input-login" 
                                            id="Email" aria-describedby="emailHelp" onChange={(e)=> onChange(e)} name="email"/>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label" id="form-label-pass">Contraseña</label>
                                        <div className='input-group'>
                                            <span className="input-group-text"><FaLock /></span>
                                            <input required type="password" className="form-control input-login" id="Password" 
                                            onChange={(e)=> onChange(e)} name="password" />
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                    <button className="btn btn-primary btn-login" type="submit" >Registrarme</button>
                                    </div>
                                    <div className="mt-4">
                                    <span className="justify-center text-sm text-center text-gray-500 flex-items-center dark:text-gray-400">
                                        ¿Ya tienes una cuenta?
                                        <Link to="/" className="text-sm text-blue-500 underline hover:text-blue-700">
                                            <b> Inicia sesión</b>
                                        </Link>
                                    </span>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            );
    }

export default Register;