import React, {useState, useEffect} from "react";
import {Form, Modal, Button} from "react-bootstrap";
import axios from "../../../../api/axios";
import {confirmAlert} from "react-confirm-alert";
import {BsFillEyeFill, BsFillEyeSlashFill} from 'react-icons/bs';

const ModalAdd = ({ show, closeModal, getUsers }) => {
    //ver contraseña
    const [ ver, setVer ] = useState(false);
    //
    const [loading, setLoading] = useState(false);
    const [ message, setMessage ] = useState();
    const [inv, setInv] = useState([]);
    const [inputs, setInputs] = useState({
        rol: "2",
        nombre: "",
        inst_procedencia: "",
        linea_inv: "",
        email: "",
        password: ""
    });
    const { rol, nombre, inst_procedencia, linea_inv, email, password } = inputs;

    useEffect(() => { 
        getInv();
    },[]); 

    const getInv = async () => {
        const res = await axios.get('/lineas');
        setInv(res.data)
        //console.log(inv)
    }

    const onChange = (e) => {
        //console.log(e.target.name)
        setInputs({...inputs, [e.target.name]: e.target.value });
        const onShow = ({ currentTarget }) => password(currentTarget.value);
    };

    //ver password
    const switchShown = () => setVer(!ver);
    const onShow = ({ currentTarget }) => password(currentTarget.value);
    //

    const addInv = async (e) => {
        e.preventDefault();
        const User = {
            rol,
            nombre,
            inst_procedencia,
            linea_inv,
            email,
            password,
        }
        setLoading(true);
        await axios.post('/registrar', User).then(({data}) => {
            setMessage(data.message)
            setInputs({
                rol: "2",
                nombre: "",
                inst_procedencia: "",
                linea_inv: "",
                email: "",
                password: ""
            });
            closeModal(true);
            confirmAlert({
                title: 'Felicitaciones',
                message:
                        'Investigador creado con exito!',
                buttons: [
                    {
                        label: 'Continuar',
                    }
                ]
            });
            setTimeout(() => {
                getUsers();
            }, 2000)
        })
        axios.post('/sendmailCreateInv', { nombre, email, password })
      .then(response => {
        // console.log(response.data.message);
      })
        .catch((err) => {
            setTimeout(() => {
                setMessage('');
                setLoading(false);
            }, 3000)
        })
    }

    

    return (
<>
    <Modal show={show} onHide={closeModal} size='lg'>
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                INGRESA LOS DATOS SOLICITADOS
            </Modal.Title>
        </Modal.Header>
            <Form method="POST" onSubmit={(e)=> addInv(e)}>
                    <div className="col-md-12">
                        <br></br>
                        <div className="modal-body">
                            <div className="col-md-8 mb-2">
                            <h5>Nombre:</h5>
                            <input required placeholder="Nombre del investigador" type="text" 
                            onChange={(e)=> onChange(e)} name="nombre" className="form-control" autoComplete="off"/>
                            </div>

                            <div className="col-md-8 mb-2">
                            <h5>Institución de procedencia:</h5>
                            <input type="text" placeholder="Institución de procedencia" name="inst_procedencia" className="form-control"
                                onChange={(e)=> onChange(e)}/>
                            </div>

                            <div className="col-md-8 mb-2">
                            <h5>Linea de investigación:</h5>
                            <Form.Select required className="form-control" id='linea_inv' 
                                onChange={(e)=> onChange(e)} name="linea_inv">
                                    <option>Selecciona una linea de investigación</option>
                                    //en el value se agrega el dato que viene desde la api ya sea nombre id etc.
                                        {inv.map(result => 
                                            <option key={result.id} value={result.nombre}>{result.nombre}</option>
                                        )};
                                </Form.Select>
                            </div>

                            <div className="col-md-8 mb-2">
                            <h5>Email:</h5>
                            <input required placeholder="example@gmail.com" type="email" name="email" className="form-control"
                                onChange={(e)=> onChange(e)} autoComplete="off"/>
                            </div>

                            <div className="form-group row">
                            
                            <h5>Contraseña:</h5>
                            <div className="col-md-8">
                            <input required placeholder="********" name="password" className="form-control"
                                onChange={(e)=> onChange(e)} type={ver ? 'text' : 'password'} autoComplete="off"/>
                                </div>
                                <div className="col-md-2">
                                <Button variant="outline-dark" size="sm" onClick={switchShown}>
                                {ver ? <BsFillEyeSlashFill size={'25px'}/> : <BsFillEyeFill size={'25px'}/>}
                                </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancelar
                    </Button>
                    <Button variant="primary" type="submit" >
                        Guardar
                    </Button>
                </Modal.Footer>
            </Form>
    </Modal>
</>
    )
}

export default ModalAdd;