import React from "react";

const LoadingSpinner = () => {

    return (
        <>
            <span className="loader"></span>
        </>
    )
}

export default LoadingSpinner;