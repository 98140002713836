import axios from "../../../api/axios.js";
import React from "react";
import { useState, useEffect } from "react";
import { confirmAlert } from 'react-confirm-alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import DataTable from "react-data-table-component";
import LoadingSpinner from "../../LoadingSpinner.js";
import createTheme from "../catalogos/modals/createTheme.js";

const AsignarObra = () => {
    
    const [obras, setObras] = useState([]);
    const [users, setUsers] = useState([]);
    //const [user, setUser] = useState([]);
    const [show, setShow] = useState(false);
    const [lineas, setLineas] = useState([]);
    //const {uuid} = useParams();
    const {uuid, id} = useParams();
    const [investigador_resp, setInv_resp] = useState('');
    const [investigador_resp2, setInv_resp2] = useState('');
    const [estatus, setStatus] = useState('En revisión');
    const [estatus2, setStatus2] = useState('En revisión');
    const [linea, setLinea] = useState('');
    const [linea2, setLinea2] = useState('');
    const [message, setMessage]= useState('');
    const [loading, setLoading] = useState(true);

    useEffect( () => {
        setTimeout(() => {
            getObras();
            getUsers();
            getLineasInv();
            setLoading(false);
        }, 2000)
        // console.log(obrasPorEvaluar);
    },[]);

    

    const openModal = () => {
        setShow(true);
    }

    const getUsers = async () => {
        const res = await axios.get('/usuarios');
        setUsers(res.data);
    }

    //treaer obras
    const getObras = async () => {
        const res = await axios.get('/obras');
        setObras(res.data);
    }

    const getLineasInv = async () => {
        const res = await axios.get('/lineas');
        setLineas(res.data)
        //console.log(res.data)
    }

    const updateResp = async (e) => {
        e.preventDefault();        
            //navigate('/actualizar/:id');
            if(!investigador_resp){
                closeModal();
                confirmAlert({
                    title: 'Error',
                    message:
                            'Debes seleccionar a un investigador',
                    buttons: [
                        {
                            label: 'Ok',
                            
                        },
                    ]
                })
            }else {
                closeModal();
                confirmAlert({
                    title: 'Éxito',
                    message:
                            'Investigador asignado correctamente!',
                    buttons: [
                        {
                            label: 'Ok',
                            
                        },
                    ]
                })
                await axios.put(`/asignar/${id}`, {
                    investigador_resp: investigador_resp,
                    investigador_resp2: investigador_resp2,
                    estatus: estatus,
                    estatus2: estatus2
            })
                // axios.post('/sendmailObraAssigned');
            }
            setTimeout(() => {
                getObras();
              }, 3000);
        }
    const closeModal = () => {
        setShow(false);
    }

    const handleLinea = (event) =>{
        const getLine = event.target.value;
        setLinea(getLine);
        event.preventDefault();
        //console.log(getLine)
    }

    const handleLineaTwo = (event) =>{
        const getLine = event.target.value;
        setLinea2(getLine);
        event.preventDefault();
        //console.log(getLine)
    }

    const handleChange = (event) =>{
        const getInv = event.target.value;
        setInv_resp(getInv);
    }

    const obraDownload = (uuid,filename) => {
        axios({
          url: `/file/${uuid}/${filename}`,
          method: 'GET',
          responseType: 'blob'
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        });
    };

    const solicitudDownload = (uuid,solicitud_pub) => {
        axios({
          url: `/file/${uuid}/${solicitud_pub}`,
          method: 'GET',
          responseType: 'blob'
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', solicitud_pub);
          document.body.appendChild(link);
          link.click();
        });
    };

    const handleChangeTwo = (event) =>{
        const getInv2 = event.target.value;
        setInv_resp2(getInv2);
    }

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const Columns = [
        {
            name: 'AUTOR',
            selector: row => row.nombre_autor,
            sortable: true,
            minWidth: '220px',
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'TÍTULO',
            selector: row => row.nombre_obra,
            sortable: true,
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'LINEA DE INVESTIGACIÓN',
            selector: row => row.linea_inv,
            sortable: true,
            minWidth: '200px',
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'OBRA',
            selector: row => row.filename,
            sortable: true,
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'INVESTIGADORES',
            cell: row => <div>{row.investigador_resp} <a> - </a> {row.investigador_resp2}</div>,
            sortable: true,
            width: '150px',
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'EVALUACIÓN 1',
            selector: row => row.estatus,
            sortable: true,
            width: '130px',
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'EVALUACIÓN 2',
            selector: row => row.estatus2,
            sortable: true,
            width: '130px',
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        
        {
            cell: row => 
            //{
            // //Filtramos las obras basándonos en el ID de la fila actual.
            // const obrasFiltradas = obras.filter(obra => obra.id === row.id);
            // //Verificamos si hay al menos una obra filtrada (obrasFiltradas.length > 0).
            // const obraObtenida = obrasFiltradas.length > 0 ? obrasFiltradas[0] : null;
            // //Si hay una obra, comparamos sus estatus y estatus2.
            // return obraObtenida && obraObtenida.estatus != null && obraObtenida.estatus2 != null ? (
            //     <button className="btn btn-success btn-sm" disabled>
            //       Asignada
            //     </button>
            //   ) : (
                <Link
                  to={`/obras/asignar/${row.id}`}
                  onClick={openModal}
                  className="btn btn-sm btn-success"
                >
                  Asignar
                </Link>,
            //   );
            // },
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            style: {
              background: '#d6dbdf',
              color: '#000',
            },
          },
        // {
        //     cell: row => 
        //         <Link to={`/obras/asignar/${row.id}`} onClick={openModal} 
        //         className="btn btn-sm btn-danger">Cancelar</Link>,
        //     ignoreRowClick: true,
        //     allowOverflow: true,
        //     button: true,
        //     style: {
        //         background: '#d6dbdf',
        //         color: '#000',
        //     }
        // },
        {
            cell: row =>
                <button onClick={()=>obraDownload(row.uuid,row.filename)} 
                className="btn btn-sm btn-primary">Ver Obra</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            name: 'A C C I O N E S',
            style: {
                background: '#d6dbdf',
                color: '#000',
            }
        },
        {
            cell: row =>
                <button onClick={()=>solicitudDownload(row.uuid,row.solicitud_pub)} 
                className="btn btn-sm btn-primary">Ver Solicitud</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            style: {
                background: '#d6dbdf',
                color: '#000',
            }
        },
    ];

    // const obrasPorEvaluar = obras.filter(obra => obra.estatus === null || '' && obra.estatus2 === null || '');

    const obraName = obras.filter(obra => obra.id === id).map(obraObtenida => obraObtenida.nombre_obra);
    const userEmail = obras.filter(obra => obra.uuid === uuid).map(obraObtenida => obraObtenida.email);
    const userName = obras.filter(obra => obra.id === parseInt(id)).map(obraObtenida => obraObtenida.nombre_autor);
    
    return (
       <main id="main" className="main">
         <div className="row">
            <div className="col-md-12 mt-3">
                {loading ? (
                        <LoadingSpinner/>
                ) :
                <DataTable
                    title="ASIGNAR UNA OBRA"
                    data={obras}
                    columns={Columns}
                    theme="solarized"
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    responsive
                />
                }
               </div>
            </div>
            <Modal show={show} onHide={closeModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>ASIGNAR UN INVESTIGADOR</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <input hidden type='text' name="nombre_obra" readOnly value={userEmail} className="form-control"/>
                            <input hidden type='text' name="email" readOnly value={obraName} className="form-control"/>
                            <input hidden type='text' name="nombre_autor" readOnly value={userName} className="form-control"/>
                        <Form.Label>Linea de investigación:</Form.Label>
                        <Form.Select onChange={handleLinea}>
                            <option>Selecciona Linea de Investigación</option>
                            {lineas.map(result => 
                                <option key={result.id} value={result.nombre}>{result.nombre}</option>
                            )};
                        </Form.Select>
                        <br/>
                        <Form.Label>Investigador 1:</Form.Label>
                        <Form.Select name="investigador_resp"
                        onChange={handleChange} >
                        <option>Selecciona un Investigador</option>
                            { users.filter(users => users.rol === 2 && users.linea_inv === linea ).map(result =>
                                <option key={result.id} value={result.nombre}>{result.nombre}</option>
                            )};
                        </Form.Select>
                        <br/>
                        <Form.Label>Investigador 2:</Form.Label>
                        <Form.Select name="investigador_resp2"
                        onChange={handleChangeTwo} >
                        <option value=''>Selecciona un Investigador</option>
                            { users.filter(users => users.rol === 2 && users.linea_inv === linea ).map(result =>
                                <option key={result.id} value={result.nombre}>{result.nombre}</option>
                            )};
                        </Form.Select>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        Cancelar
                    </Button>
                    <Button type="submit" variant="primary" onClick={updateResp}>
                        Asignar
                    </Button>
                    </Modal.Footer>
                </Modal>
       </main>
    )
}

export default AsignarObra;