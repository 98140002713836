import axios from "../../../api/axios.js";
import React from "react";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {Card, Form, Modal} from 'react-bootstrap';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
import ModalRevision from "./modals/ModalRevision.js";
import DataTable from "react-data-table-component";
import LoadingSpinner from "../../LoadingSpinner.js";

const ObrasGeneradas = () => {

    const [obras, setObras] = useState([]);
    const [show, setShow] = useState(false);
    const [ name, setName ] = useState();
    const {id} = useParams();
    const [uuid, setUuid] = useState('');
    const [loading, setLoading] = useState(true);
    const token = localStorage.getItem("token");
    const [email2, setEmail2] = useState([]);
    const [filaSeleccionada, setFilaSeleccionada] = useState([]);
    const openModal = () => {
        setShow(true);
    }

    const handleEvaluar = (uuid) => {
        const filaSeleccionada = obras.find((fila) => fila.uuid === uuid);
        setFilaSeleccionada(filaSeleccionada);
        setShow(true);
    }

    const closeModal = () => {
        setShow(false);
    }

    useEffect( ()=>{
        setTimeout(() => {
            getObras();
            getObraById();
            setLoading(false);
        }, 2000)
        if(token) {
            axios.get(`/usuario`, {
                headers: {
                    token: token,
                },
            }).then(({data}) => setName(data.nombre)).
            catch((error) => console.log(error));
        }
    },[token]);

    const descargarArchivo = (uuid,file) => {
        axios({
          url: `/file/${uuid}/${file}`,
          method: 'GET',
          responseType: 'blob'
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', file);
          document.body.appendChild(link);
          link.click();
        });
    };

        //Obtener todas las obras
    const getObras = async () => {
        const res = await axios.get('/obras');
        setObras(res.data);
    }
    
    const getObraById = async () => {
        await axios.get('/obras/'+id)
    }

    const Columns = [
        {
            name: 'AUTOR',
            selector: row => row.nombre_autor,
            sortable: true,
            style: {
                fontSize: '16px',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'TÍTULO',
            selector: row => row.nombre_obra,
            sortable: true,
            style: {
                fontSize: '16px',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'LÍNEA DE INVESTIGACIÓN',
            selector: row => row.linea_inv,
            sortable: true,
            style: {
                fontSize: '16px',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'FECHA DE CREACIÓN',
            width: '165px',
            selector: row => row.createdAt,
            sortable: true,
            style: {
                fontSize: '16px',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            cell: row =>
                <button onClick={()=>descargarArchivo(row.uuid,row.filename)} 
                className="btn btn-sm btn-success" 
                style={{marginRight: '10px',}}>Descargar</button>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            style: {
                background: '#d6dbdf',
                color: '#000',
            }
        },
        {
            cell: row =>
                <Link to={`/evaluacion/${row.uuid}`} onClick={() => handleEvaluar(row.uuid)}
                className="btn btn-sm btn-primary" 
                style={{marginRight: '10px',}}>Evaluar</Link>,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            style: {
                background: '#d6dbdf',
                color: '#000',
            }
        },
    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    // const obrasAsignadas = obras.filter(obras => obras.investigador_resp === name &&  
    // obras.investigador_resp2 === name);

    // const obrasAsignadas = obras.filter(obra => {
    //     return obra.estatus === 'En revisión' && obra.investigador_resp === name || 
    //     obra.investigador_resp2 === name && obra.estatus2 === 'En revisión';
    // });
    
    const obrasAsignadas = obras.filter(obra => {
        if ((obra.estatus === 'En revisión' && obra.investigador_resp === name) || 
            (obra.investigador_resp2 === name && obra.estatus2 === 'En revisión')) {
            // Agrega aquí cualquier otra condición que desees verificar
            // antes de incluir la obra en el resultado
            return true;
        } else {
            return false;
        }
    });
        
    return (
        <main id="main" className="main">
            { name != null ? 
            <div className="row">
                <div className="row">
                    <div className="col">
                    </div>
                <div className="col-md-12 mt-3" id="table-soli">
                {loading ? (
                        <LoadingSpinner/>
                ) :
                    <DataTable
                        title="EVALUAR UNA OBRA"
                        data={obrasAsignadas}
                        columns={Columns}
                        theme="solarized"
                        noDataComponent="No hay datos para mostrar..."
                        pagination
                        paginationComponentOptions={paginationComponentOptions}
                        responsive
                    />
                }
                    </div>
                    </div>  
                    <ModalRevision show={show} closeModal={closeModal} size='lg' filaSeleccionada={filaSeleccionada}/>
                </div>
                : 
                <div className="row">
                    <div className="row">
                        <div className="col-md">
                        </div>
                        <div className="col-md-12 mt-5">
                        <Card bg="light" style={{marginTop:'20px'}}>
                            <Card.Header as="h5" >Sin Obras Asignadas</Card.Header>
                            <Card.Body>
                                <Card.Text>Actualmente no cuentas con obras asignadas.</Card.Text>
                            </Card.Body>
                        </Card>
                        </div>
                    </div> 
                </div>
                }
        </main>
    )
}

export default ObrasGeneradas;