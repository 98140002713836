import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "../../../../api/axios";
import { useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";

const ModalCorrecciones = ({ show, closeModal, getObras }) => {
    const [file, setFile] = useState([]);
    const [fileName, setFileName] = useState('');
    const {id,uuid} = useParams();
    const [estatus] = useState('Correcciones');
    const [observaciones] = useState('Correcciones');

    const navigate = useNavigate();

    const fileSelected = (e) => {
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
        // console.log(e.target.files[0].name);
    };

    const updateStatus = async () => {
        try {
            await axios.put(`/updateFile/${uuid}`, {
              observaciones: observaciones,
              estatus: estatus,
              estatus2: estatus
            });
            getObras();
        } catch (error) {
          // Manejar errores aquí si es necesario
          console.error(error);
        }
    }

    const handlerSendFile = async (e) => {
        e.preventDefault();
        const bodyFormData = new FormData();
        bodyFormData.append("file", file);
        const config = {
            headers: {
              'content-type': 'multipart/form-data',
            },
        }
        await axios.post(`/correccion/${uuid}`, bodyFormData, config).then((resp) => {
            const { data } = resp;
            // console.log(id)
            setTimeout(() => {
                confirmAlert({
                    title: 'Felicitaciones!',
                    message:
                            `${data.message}`,
                    buttons: [
                        {
                            label: 'Continuar',
                            
                        },
                    ]
                })
            }, 1000);
            getObras();
        })
        // crear ruta en el backend para enviar correo yua que se carguen las correcciones
        await axios.post('/sendMailCreateObraAdmin', bodyFormData.email );
        updateStatus();
        closeModal();
        navigate('/inicio');
    }

    return (
        <>
            <Modal show={show} onHide={closeModal}> 
                    <Modal.Header closeButton>
                    <Modal.Title>CARGAR DOCUMENTO</Modal.Title>
                    </Modal.Header>
                    <Form method="POST" encType="multipart/form-data" onSubmit={(e)=> handlerSendFile(e)}>
                    <Modal.Body>
                        <div className="col-md-12 mb-2">
                            <input required type="file"name="file" 
                            onChange={fileSelected} className="form-control"/>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>Cancelar</Button>
                    <Button type="submit" variant="primary">Enviar</Button>
                    </Modal.Footer>
                    </Form>
            </Modal>
        </>
    )

}

export default ModalCorrecciones;