import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import axios from "../../../../api/axios";
import { useParams } from "react-router-dom";

const ModalEvaluacion = ({effectLoading, getEvaluaciones, show, fila, closeModal}) => {

    const [estatus, setEstatus] = useState('');
    const [obras, setObras] = useState([]);
    const {id,uuid} = useParams();
    const [selectedValue, setSelectedValue] = useState('');

    useEffect(() => {
      getEvaluaciones();
      getObras();
    },[]);

    const handleTextareaChange = (e) => {
      setSelectedValue(e.target.value);
    };

    const getObras = async () => {
      const res = await axios.get('/obras');
      setObras(res.data);
    }

    const inv = fila.nombre_evaluador;
    const case1 = obras.some(investigador => investigador.investigador_resp === inv);
    const case2 = obras.some(investigador => investigador.investigador_resp2 === inv);
    
    const updateStatus = async (e) => {
      e.preventDefault();
      try {
        if (case1) {
          await axios.put(`/updateEvaluation/${id}/${uuid}`, {
            observaciones: estatus,
            estatus: estatus,
            dictamen_consejo: selectedValue
          });
          closeModal();
          effectLoading();
          getEvaluaciones();
        } else if (case2) {
          await axios.put(`/updateEvaluation/${id}/${uuid}`, {
            observaciones: estatus,
            estatus2: estatus,
            dictamen_consejo: selectedValue
          });
          closeModal();
          effectLoading();
          getEvaluaciones();
        } else {
            console.log('no se se presenta ningun case...')
        }
      } catch (error) {
        // Manejar errores aquí si es necesario
        console.error(error);
      }
    }
    
    const onChange = (e) => {
      const option = e.target.value;
      setEstatus(option);
      // console.log(option);
    }

    return (
        <Modal show={show} onHide={closeModal} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Evaluación relizada por {fila.nombre_evaluador}</Modal.Title>
            </Modal.Header>
            <Form onSubmit={(e)=>updateStatus(e)}>
            <Modal.Body>
              <Form.Label>Cambios formales: </Form.Label>
              <Form.Control as="textarea" defaultValue={fila.cambios_formales} readOnly rows={5}/>
              <hr/>
              <Form.Label>Cambios formales opcionales: </Form.Label>
              <Form.Control as="textarea" defaultValue={fila.cambios_formales_opc} readOnly rows={5}/>
              <hr/>
              <Form.Label>Cambios formales: </Form.Label>
              <Form.Control as="textarea" defaultValue={fila.cambios_formales} readOnly rows={5}/>
              <hr/>
              <Form.Label>Cambios formales: </Form.Label>
              <Form.Control as="textarea" defaultValue={fila.cambios_formales} readOnly rows={5}/>
              <hr/>
              <Form.Label>Razón del dictamen: </Form.Label>
              <Form.Control as="textarea" defaultValue={fila.dictamen_razon} readOnly rows={5}/>
              <hr/>
              <Form.Label>Dictamen de {fila.nombre_evaluador}: </Form.Label>
              <Form.Control defaultValue={fila.dictamen} readOnly/>
              <hr/>
              <Form.Label>Dictaminar: </Form.Label>
              <Form.Select onChange={onChange} defaultValue='default'>
                <option value='default' disabled>Selecciona una opcion</option>
                <option value='Aceptada'>Aceptar obra</option>
                <option value='Comentarios'>Mandar comentarios a {fila.evaluado}</option>
                <option value='No publicable'>No publicable</option>
              </Form.Select>
              {estatus === 'No publicable' ? 
              <>
                <textarea value={selectedValue} onChange={handleTextareaChange} className="form-control mt-2" placeholder="Agrega los motivos por los que la obra no es publicable para que el autor."/>
              </>
              : null}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={closeModal}>Cancelar</Button>
              <Button variant="primary" type="submit">Enviar</Button>
            </Modal.Footer>
            </Form>
        </Modal>
      );

}

export default ModalEvaluacion;