import Logo from '../assets/image/logo_ciatej.png'; 
import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import * as BsIcons from 'react-icons/bs';
import * as AiIcons from 'react-icons/ai';
import * as FiIcons from 'react-icons/fi';
import * as RiIcons from 'react-icons/ri';
import * as MdIcons from 'react-icons/md';
import * as GiIcons from 'react-icons/gi';
import { FaRegNewspaper, FaNewspaper } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios.js';
import { confirmAlert } from 'react-confirm-alert';
import LogoutModal from './views/modals/logoutModal';

const MenuItem = () => {

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [role, setRole] = useState([]);
  const [name, setName] = useState([]);
  const [dobleRol, setDobleRol] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      axios
        .get('/usuario', {
          headers: {
            token: token,
          },
        })
        .then(({ data }) => setRole(data.rol) & setName(data.nombre) & setDobleRol(data.dobleRol))
        .catch((error) => console.error(error));
    }
    //console.log(dobleRol)
  }, [token]);

  const handleShow = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  //  useEffect(() => {
  //   if (token) {
  //     axios
  //       .get(USR, {
  //         headers: {
  //           token: token,
  //         },
  //       })
  //       .then(({ data }) => setName(data.nombre))
  //       .catch((error) => console.error(error));
  //   }
  // }, [token]);

   const alert = () => {
    confirmAlert({
      title: 'Cerrar sesión',
      message: '¿Seguro que deseas cerrar sesión?',
      buttons: [
        {
          label: 'Cancelar',
        },
        {
          label: 'Si',
          onClick: () => logout()
        },
      ],
      buttonsProps: {
        style: {
          backgroundColor: 'green',
          color: 'red',
        },
      },
    });
  }
  const logout = () => {
  localStorage.clear('token');
  navigate('/');
  }

  const [active, setActive] = useState(false)
  const activeSide = () => { setActive(!active) }

    return(
        <div>
        {token ? 
        <nav className="navbar fixed-top" id='header-login'>
            <div className="container-fluid">    
                <img src="https://ciatej.mx/assets/img/brand/brand_ciatejw.svg" style={{width: '90px', height: '55px', marginLeft:'10px'}} />  
                <GiIcons.GiHamburgerMenu className="menu-toggle" onClick={activeSide} />      
            </div>
        </nav>
        : '' }

        { token  ?
        <div className={!active ? 'sidebar':'sidebar-sh'}>         
            <li><AiIcons.AiOutlineUser size={'20px'} style={{marginLeft: '10px'}}/>  &nbsp; {name}</li>
            <hr/>
          { role >= 0 ?
            <ul>
                {/* <li className='drop'><Link style={{fontSize: '18px'}} to="#" className='btn dropd'><AiIcons.AiOutlineUser size={'25px'}/>  &nbsp; {name}</Link></li> */}
                
                {/* <li><Link className="nav-link" ><MdIcons.MdLogout size={'20px'} /> Cerrar sesión </Link></li> */}
                {role >= 0 ?
                <li className='drop'><Link to="/inicio" className='btn dropd'><AiIcons.AiOutlineHome/>  &nbsp; Escritorio</Link></li>
                : '' }
                { role === 0 ?
                <li className='drop'>
                <Link className='btn dropd' data-bs-toggle="collapse" to="#collapseCatalogos">
                <FaRegNewspaper style={{color:'#fff'}} /> &nbsp; Catalogos<RiIcons.RiArrowDownSFill style={{float:'right', marginTop:'6px', color:'#fff'}}/></Link> 
                <div className="collapse" id="collapseCatalogos">
                    <ul className='uldrop'>
                        <li className='drop'><Link to="/catalogo/investigadores" className='btn dropd'><RiIcons.RiBookletLine /> &nbsp; Investigadores</Link></li>
                    </ul>
                </div>
                </li>
                : ''}
                { role === 1 ?
                <li className='drop'>
                <Link className='btn dropd' data-bs-toggle="collapse" to="#collapseCatalogos">
                <FaRegNewspaper style={{color:'#fff'}} /> &nbsp; Catalogos<RiIcons.RiArrowDownSFill style={{float:'right', marginTop:'6px', color:'#fff'}}/></Link> 
                <div className="collapse" id="collapseCatalogos">
                    <ul className='uldrop'>
                        <li className='drop'><Link to="/catalogo/lineas_investigacion" className='btn dropd'><FaNewspaper /> &nbsp; Colecciones </Link></li>
                    </ul>
                </div>
                </li>
                : ''}
                <li className='drop'>
                <Link className='btn dropd' data-bs-toggle="collapse" to="#collapseObras">
                <FiIcons.FiBook style={{color:'#fff'}} /> &nbsp; Obras<RiIcons.RiArrowDownSFill style={{float:'right', marginTop:'6px', color:'#fff'}}/></Link> 
                <div className="collapse" id="collapseObras">
                    <ul className='uldrop'>
                        {(role === 1 || role === 2 && dobleRol === 'Activo') ? 
                        <li className='drop'><Link to="/obras/alta" className='btn dropd'><BsIcons.BsBook /> &nbsp; Generar</Link></li>
                        : '' }
                        {role === 2 ?
                        <li className='drop'><Link to="/obras/solicitudes" className='btn dropd'><BsIcons.BsBook /> &nbsp; Mis Obras</Link></li>
                        : '' }
                        {role === 0 ? 
                        <li className='drop'><Link to="/obras/asignar" className='btn dropd'><BsIcons.BsBook /> &nbsp; Asignar</Link></li>
                        : '' }
                        {role === 0 ? 
                        <li className='drop'><Link to="/obras/obrasEvaluadas" className='btn dropd'><BsIcons.BsBook /> &nbsp; Evaluadas</Link></li>
                        : '' }
                        {role === 0 ?
                        <li className='drop'><Link to="/obras/obrasAceptadas" className='btn dropd'><BsIcons.BsBook /> &nbsp; Aceptadas</Link></li>
                        : '' }
                        {role === 0 ?
                        <li className='drop'><Link to="/obras/obrasPublicadas" className='btn dropd'><BsIcons.BsBook /> &nbsp; Publicadas</Link></li>
                        : '' }
                    </ul>
                </div>
                </li>
                {/* {role === 0 ? 
                <li className='drop'><Link to="/usuarios" className='btn dropd'><FiIcons.FiUsers/> &nbsp; Control de Usuarios</Link></li>
                : '' } */}
                <hr/>
                <li className='drop'><button onClick={()=> handleShow()}  className='btn dropd'><MdIcons.MdLogout size={'18px'}/>  &nbsp; Cerrar sesión </button></li>
            </ul>
            : ''}
        </div>
        : ''}
        <LogoutModal open={open} handleClose={handleClose}/>
      </div>
    )
}

export default MenuItem;