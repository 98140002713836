import { Navigate, Outlet } from "react-router-dom"

const ProtectedRoutes = ({
      redirectPath = '/'
}) => {
  const canActivate = localStorage.getItem('token');
  if (!canActivate) {
    return <Navigate to={redirectPath} replace/>
  }
  return <Outlet/>
}

export default ProtectedRoutes;