import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from './api/axios';

const ExpireToken = () => {

    const navigate = useNavigate();
    const tokenUser = localStorage.getItem("token");
    const tokenExpiracion = 1500000;  //8 horas en milisegundos 

    useEffect(() => {
        
        if (tokenUser) {
          axios.get('/usuario', {
              headers: {
              token: tokenUser,
              },
          })
          .then(({ data }) => (data))
          .catch((error) => console.error(error));
        } 

        const tokenTimer = setTimeout(() => {
          localStorage.removeItem('token');
          navigate('/'); 
        }, tokenExpiracion);
      
        return () => clearTimeout(tokenTimer);
      },[tokenUser, navigate]);   

}
export default ExpireToken;