import React, { useState } from "react";
import Logo from "../assets/image/logo-ciatej.jpg";
import { Link, useNavigate } from "react-router-dom";
import '../assets/css/login.css';
import axios from '../api/axios.js';
import { FaLock } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

const Login = () => {
    const navigate = useNavigate();

    const [inputs, setInputs] = useState({ email: "", password: "" })
    const [ message, setMessage ] = useState();
    const [ loading, setLoading ] = useState(false);
    const { email, password } = inputs;

    const handleChange  = (e) => {
        setInputs({...inputs, [e.target.name]: e.target.value });
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (email !== "" && password !== "") {
          const User = {
            email,
            password,
          };
          setLoading(true);
          await axios
            .post('/login', User)
            .then((res) => {
              const { data } = res;
              setTimeout(() => {
                //setMessage(data.message);
                confirmAlert({
                    title: '',
                    message:
                            `${data.message}`,
                    buttons: [
                        {
                            label: 'Aceptar',
                        }
                    ]
                })
              }, 500);
                localStorage.setItem("token", data.user.token);
                //console.log(localStorage);
                navigate('/inicio');
            })
            .catch((error) => {
                setTimeout(() => {
                setMessage(error.message);
                }, 1500);
            });
          setInputs({ email: "", password: "" });
          setLoading(false);
        }
      };
    
        return(
        <>
        <div id="background-login">
            <div className="container">
                    <div className="col-lg-12">
                        <form id="form-login" className="text-center form-login" onSubmit={(e)=> onSubmit(e)}>
                            <img src={Logo} className="logo-log mb-4" alt="logo" />
                            <div className="mb-3">
                                <label className="form-label login-label">Correo electrónico</label>
                                <div className="input-group">
                                    <span className="input-group-text"><GrMail /></span>
                                    <input type="email" className="form-control input-login" value={email} id="Email" name="email"
                                     onChange={(e)=> handleChange (e)} required/>
                                </div>
                            </div>
                            <div className="mb-3">
                                <label className="form-label" id="form-label-pass">Contraseña</label>
                                <div className="input-group">
								    <span className="input-group-text"><FaLock /></span>
                                    <input type="password" className="form-control input-login" value={password} id="Password" name="password"
                                     onChange={(e)=> handleChange (e)} required/>
							    </div>
                            </div>
                            <div className="mb-3">
                                <button type="submit" className="btn btn-primary mt-3">
                                    { loading ? 'Cargando...' : 'Iniciar sesión'}
                                </button>
                            </div>
                            <div className="mb-3 text-center">
                                <p>No tienes una cuenta?  <Link className="register" type="button" to='/register'>Registrate Aquí</Link></p>
                            </div>
                            <div className="mb-3 text-center">
                            &copy; <a href="https://ciatej.mx/" target="_blank" className="register">CIATEJ</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            </>
        );
    }
    
export default Login;