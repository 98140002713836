import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import axios from "../../../api/axios";
import LoadingSpinner from "../../LoadingSpinner";

const ObrasPublicadas = () => {
    const [obras, setObras] = useState([]);
    const [name, setName] = useState([]);
    const token = localStorage.getItem("token");
    const [loading, setLoading] = useState(true);

    useEffect( ()=>{
        if(token) {
            axios.get(`/usuario`, {
                headers: {
                    token: token,
                },
            }).then(({data}) => setName(data.nombre)).
            catch((error) => console.log(error));
        }
        setTimeout(() => {
            getObras();
            setLoading(false);
        }, 2000)
    },[token]);

    const getObras = async () => {
        const res = await axios.get('/obras');
        setObras(res.data);
    }

    const Columns = [

        {
            name: 'Nombre del autor',
            selector: row => row.nombre_autor,
            sortable: true,
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'Nombre de la obra',
            selector: row => row.nombre_obra,
            sortable: true,
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'Linea de investigación',
            selector: row => row.linea_inv,
            sortable: true,
            width: '250px',
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'ISBN',
            selector: row => row.isbn,
            sortable: true,
            width: '250px',
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
        {
            name: 'Fecha de aceptación',
            selector: row => row.updatedAt,
            sortable: true,
            width: '145px',
            style: {
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
    
    ];
    
//modificar a evaluar por ISBN
    const obrasPublicadas = obras.filter((obra) => {
        return obra.isbn !== null && obra.isbn !== '';
    });

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    return(
        <main id="main" className="main">
              <div className="row">
                <div className="col-md-12 mt-3">                   
                    { loading ? (
                        <LoadingSpinner/>
                    ) :
                    <DataTable
                        title="OBRAS PUBLICADAS"
                        theme="solarized"
                        data={obrasPublicadas}
                        columns={Columns}
                        pagination
                        responsive
                        paginationComponentOptions={paginationComponentOptions}
                    />
                    }
                </div>
            </div>
        </main>
    )
}

export default ObrasPublicadas;