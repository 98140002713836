import React, { useState, useEffect } from "react";
import Logo from "../assets/image/logo-ciatej.jpg";
import { Link, useNavigate } from "react-router-dom";
import '../assets/css/login.css';
import axios from '../api/axios.js';
import { confirmAlert } from "react-confirm-alert";
import { FaLock, FaUserAlt, FaBook } from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { GiFamilyHouse } from "react-icons/gi";
import { Form } from "react-bootstrap";

const RegisterInv = () => {

    const navigate = useNavigate();

    const [inputs, setInputs] = useState({
        rol: "2",
        nombre: "",
        inst_procedencia: "",
        linea_inv: "",
        email: "",
        password: ""
    });
    const [loading, setLoading] = useState(false);
    const [ message, setMessage ] = useState();
    const [inv, setInv] = useState([]);
    const { rol, nombre, inst_procedencia, linea_inv, email, password } = inputs;

    const onChange = (e) => {
        //console.log(e.target.name)
        setInputs({...inputs, [e.target.name]: e.target.value });
    };

    useEffect(() => { 
        getInv();
        //console.log(inv)
    },[]);    

    const getInv = async () => {
        const res = await axios.get('/lineas');
        setInv(res.data)
        //console.log(inv)
    }

    const onSubmit = async (e) => {
        //console.log('olis')
        e.preventDefault();
        if(nombre !== '' && email !== '' && password !== '') {
            const User = {
                rol,
                nombre,
                inst_procedencia,
                linea_inv,
                email,
                password,
            }
            setLoading(true);
            await axios.post('/registrar', User).then(({data}) => {
                setMessage(data.message)
                setInputs({
                    rol: "2",
                    nombre: "",
                    inst_procedencia: "",
                    linea_inv: "",
                    email: "",
                    password: ""
                });
                confirmAlert({
                    title: 'Felicitaciones',
                    message:
                            'Tu cuenta a sido creada con exito!',
                    buttons: [
                        {
                            label: 'Continuar',
                        }
                    ]
                })
                navigate('/')
            }).catch((error) => {
                setTimeout(() => {
                    setMessage('')
                    setLoading(false);
                }, 3000);
            })
        }
    } 


        return(
                <>
                <div id="background-login">
                    <div className='container'>
                        <div className="col-lg-12">
                            <form className="text-center form-register-inv" onSubmit={(e)=> onSubmit(e)}>
                            <img src={Logo} className="logo-log mb-4" alt="logo" />

                                
                                <div className="mb-3">
                                    <label className="form-label input-name">Nombre Completo</label>
                                    <div className='input-group'>
                                        <span className="input-group-text"><FaUserAlt /></span>
                                        <input required className="form-control input-login" id='Nombre' 
                                        onChange={(e)=> onChange(e)} name="nombre" />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label input-inst">Institución De Procedencia</label>
                                    <div className='input-group'>
                                        <span className="input-group-text"><GiFamilyHouse /></span>
                                        <input required className="form-control input-login" id='Inst_procedencia' 
                                        onChange={(e)=> onChange(e)} name="inst_procedencia" />
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label input-linea-inv">Linea de Investigación</label>
                                    <div className='input-group-inv'>
                                        <Form.Select required className="form-control" id='linea_inv' 
                                        onChange={(e)=> onChange(e)} name="linea_inv">
                                            <option>Selecciona una linea de investigación</option>
                                            //en el value se agrega el dato que viene desde la api ya sea nombre id etc.
                                                {inv.map(result => 
                                                    <option key={result.id} value={result.nombre}>{result.nombre}</option>
                                                )};
                                        </Form.Select>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label login-email">Correo Electrónico</label>
                                    <div className='input-group'>
                                        <span className="input-group-text"><GrMail /></span>
                                        <input required type="email" className="form-control input-login" 
                                        id="Email" aria-describedby="emailHelp" onChange={(e)=> onChange(e)} name="email"/>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label className="form-label" id="form-label-pass">Contraseña</label>
                                    <div className='input-group'>
                                        <span className="input-group-text"><FaLock /></span>
                                        <input required type="password" className="form-control input-login" id="Password" 
                                        onChange={(e)=> onChange(e)} name="password" />
                                    </div>
                                </div>
                                <div className="mt-4">
                                <button className="btn btn-primary btn-login" type="submit" >Registrarme</button>
                                </div>
                                <div className="mt-3">
                                <Link to={'/'} className="btn btn-secondary btn-login" >Cancelar</Link>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </>
        );
    }

export default RegisterInv;