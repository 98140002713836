import axios from "../../../api/axios.js";
import React from "react";
import { useState, useEffect } from "react";
import DataTable, {Expander} from 'react-data-table-component';

const LineasInv = () => {
    const [lineas, setLineas] = useState([]);

    useEffect(() => {  
        getLineasInv();
    },[]);
    
    const Columns = [
        {
            name: 'Nombre',
            selector: row => row.nombre,
            sortable: true,
            style: {
                fontSize: '15px',
                fontWeight: 'bold',
                background: '#d6dbdf',
                color: '#000',
                '&:hover': {
                    background: '#ccc'
                  },
            }
        },
    ];

    const paginationComponentOptions = {
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'Todos',
    };

    const getLineasInv = async () => {
        const res = await axios.get('/lineas');
        setLineas(res.data);
    }

    const ExpandedComponent = ({ data }) => <p style={{color: 'lightgrey', fontSize: '20px',
    paddingLeft: '40px'}}>
        {JSON.stringify(data.descripcion, null, 2)}</p>;
    
    return (
        <main id="main" className="main">
             <div className="row">
                <div className="col-md-12 mt-3">
                <DataTable
                    title="COLECCIONES"
                    columns={Columns}
                    data={lineas}
                    theme="solarized"
                    pagination
                    noDataComponent="No hay datos para mostrar..."
                    paginationComponentOptions={paginationComponentOptions}
                    responsive
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    // expandableRows
                    // expandableRowsComponent={ExpandableComponent}
                />
                </div>
            </div>
        </main>      
    )

}


export default LineasInv;